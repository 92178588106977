import { FC, useMemo, useState } from 'react';
import moment from 'moment';

import {
  Maybe,
  OrdersListQuery,
  OrderSortKeys,
  OrderStatusEnum,
  useOrdersListQuery,
} from '@/apolloGenerated';
import {
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'id' },
  { sortable: true, title: 'Покупка', value: 'createdAt' },
  { title: 'Событие', value: 'event' },
  { title: 'Начало', value: 'startDate' },
  { title: 'Завершение', value: 'endDate' },
  { title: 'Потрачено, ₽ ', value: 'price' },
  { title: 'Потрачено, Баллы ', value: 'bonus' },
];
const pageSize = 10;

export interface UserOrdersTableProps {
  uuidUser: string;
  status: OrderStatusEnum[];
}
export const UserOrdersTable: FC<UserOrdersTableProps> = ({
  status,
  uuidUser,
}) => {
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  const {
    data: curData,
    loading,
    previousData,
  } = useOrdersListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
      status,
      uuidUser,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (order: OrdersListQuery['ordersList']['rows'][0]) => ({
    bonus: order.userBalanceWithdrawal?.amount
      ? formatAmount(order.userBalanceWithdrawal?.amount)
      : '-',
    createdAt: renderCellItemValue({
      label: `ID: ${order.id}`,
      value: moment(order.createdAt).format('DD MMM YYYY HH:mm'),
    }),
    endDate: renderCellDate({ time: true, timestamp: order.product.endDate }),
    event: renderCellItemValue({
      label: order.product.name,
      value: order.tariff.name,
    }),
    id: String(order.id),
    price: order.payment?.price ? formatAmount(order.payment?.price) : '-',
    startDate: renderCellDate({
      time: true,
      timestamp: order.product.startedDate,
    }),
  });
  const items = useMemo(
    () => (data?.ordersList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.ordersList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
