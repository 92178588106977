import { gql } from '@apollo/client';

export const user = gql`
  query User($uuid: UUID!, $organizerId: Float!) {
    user(uuid: $uuid) {
      createdAt
      updatedAt
      deletedAt
      uuid
      tgId
      username
      first_name
      last_name
      language
      role
      email
      phone
      ordersCount
      activatedOrdersCount
      receipts
      gender
      isSpecial(idOrganizer: $organizerId)
    }
  }
`;

export const usersStats = gql`
  query usersStats($organizerId: Float, $isActive: Boolean) {
    usersStats(
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      count
      maleCount
      femaleCount
    }
  }
`;
export const updateUser = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const usersList = gql`
  query usersList(
    $filters: UserListFilterInput
    $organizerId: Float
    $organizerSpecialId: Float!
    $isActive: Boolean
  ) {
    users(
      filters: $filters
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        activatedOrdersCount
        ordersCount
        isSpecial(idOrganizer: $organizerSpecialId)
      }
      count
    }
  }
`;

export const usersSpecialList = gql`
  query usersSpecialList($filters: UserListFilterInput, $organizerId: Float!) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        activatedOrdersCount
        ordersCount
        isSpecial(idOrganizer: $organizerId)
      }
      count
    }
  }
`;

export const usersSpecialProductList = gql`
  query usersSpecialProductList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $productUuid: UUID!
  ) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      rows {
        uuid
        first_name
        last_name
        username
        phone
        email
        gender
        hasOrderByProduct(productUuid: $productUuid)
      }
      count
    }
  }
`;

export const usersSpecialCount = gql`
  query usersSpecialCount($organizerId: Float!) {
    specialUsers(
      organizerId: $organizerId
      filters: { pagination: { page: 1, pageSize: 1 } }
    ) {
      count
    }
  }
`;

export const addUserToSpecial = gql`
  mutation addUserToSpecial($organizerId: Float!, $userUuid: UUID!) {
    addUserToSpecial(organizerId: $organizerId, userUuid: $userUuid) {
      uuid
    }
  }
`;

export const removeUserFromSpecial = gql`
  mutation removeUserFromSpecial($organizerId: Float!, $userUuid: UUID!) {
    removeUserFromSpecial(organizerId: $organizerId, userUuid: $userUuid)
  }
`;
