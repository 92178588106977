import React, { FC, useState } from 'react';

import { UserType, useTariffQuery } from '@/apolloGenerated';
import {
  ContactListFilter,
  ContactListFilterData,
  SpecialContactSelectTable,
} from '@features/Contact';
import { Maybe } from '@letsdance/ui-kit';

export interface SpecialContactSelectProps {
  tariffUuid?: Maybe<string>;
  productUuid?: Maybe<string>;
  onChange(uuids: string[]): void;
}
export const SpecialContactSelect: FC<SpecialContactSelectProps> = ({
  onChange,
  productUuid,
  tariffUuid,
}) => {
  const { data } = useTariffQuery({
    skip: !tariffUuid,
    variables: { uuid: tariffUuid! },
  });
  const [value, setValue] = useState<UserType[]>([]);
  const [filter, setFilter] = useState<ContactListFilterData>({ search: '' });

  const handleChange = (val: UserType[]) => {
    setValue(val);
    onChange(val.map((el) => el.uuid));
  };

  return (
    <div>
      <ContactListFilter initValue={filter} onChange={setFilter} />
      <SpecialContactSelectTable
        selected={value}
        onChange={handleChange}
        searchValue={filter.search}
        availableCount={data?.tariff.availableOrdersCount}
        type={data?.tariff.type}
        productUuid={productUuid}
      />
    </div>
  );
};
