import { gql } from '@apollo/client';

export const ordersList = gql`
  query ordersList(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
      rows {
        createdAt
        id
        status
        fixedHoldPercentage
        payment {
          price
        }
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          startedDate
          endDate
        }
        userBalanceWithdrawal {
          amount
        }
        tariff {
          name
        }
      }
    }
  }
`;
export const ordersCount = gql`
  query ordersCount(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
    }
  }
`;

export const giveOrders = gql`
  mutation giveOrders(
    $usersUuids: [UUID!]!
    $tariffUuid: UUID!
    $productUuid: UUID!
  ) {
    giveOrders(
      usersUuids: $usersUuids
      tariffUuid: $tariffUuid
      productUuid: $productUuid
    ) {
      status
      uuidUser
      error
    }
  }
`;
