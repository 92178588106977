import { FC, useState } from 'react';

import { Container, FloatDriver } from '@/shared';
import { PurchasesProvider } from '@entities/Purchases';
import { Button, Spacer, StarIcon } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';
import {
  GiveOrderUsers,
  PurchasesLists,
  PurchasesProductPicker,
  PurchasesStats,
} from '@widgets/Purchases';

export const PurchasesView: FC = () => {
  const [isOpenInviteScene, setIsOpenInviteScene] = useState<boolean>(false);

  return (
    <PurchasesProvider>
      <Container>
        <Head
          title="Билеты"
          rightSlot={
            <Button
              variant="outlined"
              prependIcon={StarIcon}
              onClick={() => setIsOpenInviteScene(true)}>
              Пригласить
            </Button>
          }
        />
      </Container>
      <Container>
        <PurchasesProductPicker />
        <Spacer size={12} />
        <PurchasesStats />
      </Container>
      <Spacer size={12} />
      <PurchasesLists />
      <FloatDriver
        isOpen={isOpenInviteScene}
        onClose={() => setIsOpenInviteScene(false)}>
        <GiveOrderUsers onCancel={() => setIsOpenInviteScene(false)} />
      </FloatDriver>
    </PurchasesProvider>
  );
};
