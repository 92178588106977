import { FC, useEffect, useState } from 'react';
import moment from 'moment';

import {
  ProductSortKeys,
  SortEnum,
  StatusEnum,
  useEventsListQuery,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { Chip, Maybe } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventPickerProps {
  initValue?: Maybe<string>;
  onChange(value: Maybe<string>): void;
  status?: StatusEnum[];
  hideAll?: boolean;
  autoSetFirst?: boolean;
}
export const EventPicker: FC<EventPickerProps> = ({
  autoSetFirst,
  hideAll,
  initValue,
  onChange,
  status = [StatusEnum.Active, StatusEnum.Archive],
}) => {
  const organizerId = useOrganizerId()!;
  const { data } = useEventsListQuery({
    skip: !organizerId,
    variables: {
      filters: {
        order: { sort: SortEnum.Asc, sortBy: ProductSortKeys.StartedDate },
      },
      params: {
        organizerId,
      },
      status,
    },
  });
  const [value, setValue] = useState<Maybe<string>>(initValue || null);

  const handleChange = (val: Maybe<string>) => {
    setValue(val);
    onChange(val);
  };

  useEffect(() => {
    if (autoSetFirst && !initValue && data?.productsList.rows.length) {
      handleChange(data?.productsList.rows[0].uuid);
    }
  }, [data?.productsList]);

  return (
    <div className={styles.eventPicker}>
      {!hideAll && (
        <Chip onClick={() => handleChange(null)} active={!value}>
          Все вечеринки
        </Chip>
      )}

      {(data?.productsList.rows || []).map((el) => (
        <Chip
          key={el.uuid}
          onClick={() => handleChange(el.uuid)}
          active={value === el.uuid}>
          {el.name} · {moment(el.startedDate).format('DD.MM.YYYY')}
        </Chip>
      ))}
    </div>
  );
};
