import React, { FC, useEffect, useState } from 'react';

import {
  StatusEnum,
  TariffTypeEnum,
  useGiveOrdersMutation,
} from '@/apolloGenerated';
import { ActionButtons, Container, declension, FormSection } from '@/shared';
import { EventPicker } from '@features/Event';
import { TariffPicker } from '@features/Tariff';
import { Maybe, toaster, Typography } from '@letsdance/ui-kit';
import { SpecialContactSelect } from '@widgets/Contact';
import { Head } from '@widgets/Layout';

export interface GiveOrderUsersProps {
  productUuid?: Maybe<string>;
  onCancel(): void;
}
export const GiveOrderUsers: FC<GiveOrderUsersProps> = ({
  onCancel,
  productUuid: initProductUuid,
}) => {
  const [productUuid, setProductUuid] = useState<Maybe<string>>(
    initProductUuid || null,
  );
  const [tariffUuid, setTariffUuid] = useState<Maybe<string>>(null);
  const [usersUuids, setUsersUuids] = useState<string[]>([]);
  const isDisabled = !productUuid || !tariffUuid || usersUuids.length === 0;
  const [giveOrders, { data }] = useGiveOrdersMutation();

  const handleSubmit = () => {
    if (!tariffUuid || !productUuid || usersUuids.length === 0) {
      return;
    }
    giveOrders({ variables: { productUuid, tariffUuid, usersUuids } });
  };

  useEffect(() => {
    if (data) {
      const hasErrors = data.giveOrders.some((el) => !el.status);

      if (hasErrors) {
        for (const el of data.giveOrders) {
          if (!el.status) {
            toaster.error({
              contentSlot: (
                <Typography variant="body-16">{el.error}</Typography>
              ),
              title: `Ошибка приглашения пользователья ${el.uuidUser}`,
            });
          }
        }
      } else {
        toaster.success({ title: 'Приглашения успешно отправлены' });
      }
      onCancel();
    }
  }, [data]);

  return (
    <div>
      <Container>
        <Head title="Пригласить" />
      </Container>
      <Container>
        <FormSection title="Выбрать мероприятие">
          <EventPicker
            initValue={productUuid}
            onChange={setProductUuid}
            status={[StatusEnum.Active]}
            autoSetFirst
            hideAll
          />
        </FormSection>
        <FormSection title="Выбрать тариф">
          {!!productUuid && (
            <TariffPicker
              onChange={setTariffUuid}
              productUuid={productUuid}
              status={[StatusEnum.Active]}
              hideAll
              autoSetFirst
              hideTariffType={[TariffTypeEnum.Pair]}
            />
          )}
        </FormSection>

        <FormSection title={`Список получателей (${usersUuids.length})`}>
          <SpecialContactSelect
            tariffUuid={tariffUuid}
            productUuid={productUuid}
            onChange={setUsersUuids}
          />
        </FormSection>
      </Container>
      <ActionButtons
        actions={[
          { handler: onCancel, label: 'Отменить', size: 'large' },
          {
            color: 'primary',
            disabled: isDisabled,
            handler: handleSubmit,
            label: `Оформить ${usersUuids.length} ${declension(usersUuids.length, ['билет', 'билета', 'билетов'])}`,
            size: 'large',
          },
        ]}
        hideDivider
      />
    </div>
  );
};
