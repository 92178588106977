import React, { ChangeEvent, FC, useEffect } from 'react';
import moment from 'moment';

import {
  PreviewProductInput,
  ProductFeatureFlagsInput,
  ProductInput,
  ProductType,
  RouteProductInput,
  TariffType,
} from '@/apolloGenerated';
import { DeeplinkCopy, FormSection, useImageUploader } from '@/shared';
import { EventTariffsPicker } from '@entities/Tariff';
import {
  DateTimePicker,
  ImageUploader,
  Maybe,
  NestedKeys,
  PeriodTextField,
  RadioButtons,
  Spacer,
  Switch,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventFormProps {
  initValues: ProductType;
  initTariffs: TariffType[];
  onChange(val: ProductInput): void;
  errors?: Record<NestedKeys<ProductInput>, string[]>;
  readonly?: boolean;
}
const getInfoForBeforeStart = (
  startedDate?: Maybe<string>,
  duration?: Maybe<string>,
) =>
  startedDate && duration
    ? ` (до ${moment(startedDate).subtract(duration).format('DD.MM.YY HH:mm')})`
    : '';

export const EventForm: FC<EventFormProps> = ({
  errors,
  initTariffs,
  initValues,
  onChange,
  readonly,
}) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<ProductInput>(console.log, {
    ...initValues,
    tariffsUuid: initTariffs.map((el) => el.uuid),
  });

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateFeatureFlags =
    (key: keyof ProductFeatureFlagsInput) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setBatchValues({
        featureFlags: {
          ...values.featureFlags,
          [key]: e.target.checked,
        },
      });
    };
  const updatePreview = function <T extends keyof PreviewProductInput>(
    key: T,
    value: PreviewProductInput[T],
  ) {
    setBatchValues({
      preview: {
        ...values.preview,
        [key]: value,
      },
    });
  };
  const updateRoute = function <T extends keyof RouteProductInput>(
    key: T,
    value: PreviewProductInput[T],
  ) {
    setBatchValues({
      route: {
        ...values.route,
        [key]: value,
      },
    });
  };

  return (
    <div className={styles.eventForm}>
      <FormSection title="Основная информация">
        <div className={styles.eventForm__mainInfo}>
          <ImageUploader
            initValue={values.img}
            onChange={(val) => setBatchValues({ img: val })}
            disabled={readonly}
            error={errors?.img}
            uploadImageCallback={uploadImage}
            size={300}
          />
          <div>
            <TextField
              label="Название"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.name}
              name="name"
              initValue={values.name}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <div className="grid-2">
              <DateTimePicker
                initValue={new Date(values.startedDate)}
                label={`Дата начала вечеринки (${timeZone})`}
                fullWidth
                size="large"
                onChange={(val) =>
                  setBatchValues({ startedDate: val?.toISOString() })
                }
                error={errors?.startedDate}
                disabled={readonly}
                minDate={new Date()}
              />
              <DateTimePicker
                initValue={new Date(values.endDate)}
                label={`Дата окончания вечеринки (${timeZone})`}
                fullWidth
                size="large"
                onChange={(val) =>
                  setBatchValues({ endDate: val?.toISOString() })
                }
                error={errors?.endDate}
                disabled={readonly || !values?.startedDate}
                minDate={new Date()}
              />
            </div>
            <Spacer size={10} />
            <TextField
              label="Место проведения"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.address}
              name="address"
              initValue={values.address!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Ключ диплинка (латиница)"
              size="large"
              disabled={readonly}
              fullWidth
              error={errors?.query}
              name="query"
              initValue={values.query!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <DeeplinkCopy to="event" value={values.query} />
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Описание"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.description}
          name="description"
          initValue={values.description}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Способ оплаты">
        <RadioButtons
          name="activeAcquiring"
          checked={values.activeAcquiring}
          label="Онлайн-платеж по реквизитам, указанным в профиле"
          onChange={onChangeValue}
          disabled={readonly}
        />
        <Spacer size={6} />
        <RadioButtons
          name="activeAcquiring"
          checked={!values.activeAcquiring}
          label="Оплата по инструкции"
          onChange={(e) => {
            setBatchValues({ activeAcquiring: !e.target.checked });
          }}
          disabled={readonly}
        />
        <Spacer size={6} />
        <TextField
          tag="textarea"
          label="Инструкция"
          size="large"
          style={{ height: 200 }}
          disabled={readonly || values.activeAcquiring}
          fullWidth
          error={errors?.manualBuyText}
          name="manualBuyText"
          initValue={values.manualBuyText!}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Превью">
        <Switch
          label="Активно"
          checked={values.preview.active}
          disabled={readonly}
          onChange={(e) => updatePreview('active', e.target.checked)}
        />
        {values.preview.active && (
          <>
            <Spacer size={10} />
            <div className={styles.eventForm__secondaryInfo}>
              <ImageUploader
                initValue={values.preview.url}
                onChange={(val) => updatePreview('url', val)}
                error={errors?.['preview.url']}
                disabled={readonly}
                uploadImageCallback={uploadImage}
              />
              <div>
                <TextField
                  label="Текст кнопки назад"
                  size="large"
                  disabled={readonly}
                  fullWidth
                  error={errors?.['preview.back_button_text']}
                  name="back_button_text"
                  initValue={values.preview.back_button_text!}
                  onChange={(e) =>
                    updatePreview('back_button_text', e.target.value)
                  }
                />
                <Spacer size={10} />
                <TextField
                  tag="textarea"
                  label="Описание"
                  size="large"
                  style={{ height: 190 }}
                  disabled={readonly}
                  fullWidth
                  name="description"
                  error={errors?.['preview.description']}
                  initValue={values.preview.description!}
                  onChange={(e) => updatePreview('description', e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </FormSection>
      <FormSection title="Как добраться?">
        <Switch
          label="Активно"
          checked={values.route.active}
          disabled={readonly}
          onChange={(e) => updateRoute('active', e.target.checked)}
        />
        {values.route.active && (
          <>
            <Spacer size={10} />
            <div className={styles.eventForm__secondaryInfo}>
              <ImageUploader
                initValue={values.route.url}
                onChange={(val) => updateRoute('url', val)}
                error={errors?.['route.url']}
                disabled={readonly}
                uploadImageCallback={uploadImage}
              />
              <div>
                <TextField
                  label="Текст кнопки назад"
                  size="large"
                  disabled={readonly}
                  fullWidth
                  error={errors?.['route.back_button_text']}
                  name="back_button_text"
                  initValue={values.route.back_button_text!}
                  onChange={(e) =>
                    updateRoute('back_button_text', e.target.value)
                  }
                />
                <Spacer size={10} />
                <TextField
                  tag="textarea"
                  label="Описание"
                  size="large"
                  style={{ height: 190 }}
                  disabled={readonly}
                  fullWidth
                  name="description"
                  error={errors?.['route.description']}
                  initValue={values.route.description!}
                  onChange={(e) => updateRoute('description', e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </FormSection>
      <FormSection title="Тарифы">
        <EventTariffsPicker
          initValue={initTariffs}
          disabled={readonly}
          onChange={(tariffsUuid) => setBatchValues({ tariffsUuid })}
        />
      </FormSection>
      <FormSection title="Фичи">
        <Switch
          label="Возврат билета + оплата бонусными баллама"
          checked={values.featureFlags.refund_order}
          disabled={readonly}
          onChange={updateFeatureFlags('refund_order')}
        />
        <Spacer size={10} />
        <PeriodTextField
          label={
            'Отключение в часах до начала' +
            getInfoForBeforeStart(
              values.startedDate,
              values.durationBeforeStartForRefundOrder,
            )
          }
          size="large"
          style={{ width: 500 }}
          disabled={readonly || !values.featureFlags.refund_order}
          fullWidth
          error={errors?.durationBeforeStartForRefundOrder}
          name="durationBeforeStartForRefundOrder"
          initValue={values.durationBeforeStartForRefundOrder!}
          onChange={(e) =>
            setBatchValues({
              durationBeforeStartForRefundOrder: e.target.value,
            })
          }
          period="hour"
        />
        <Spacer size={10} />
        <Switch
          label="Переоформление билета"
          checked={values.featureFlags.reregistration_order}
          disabled={readonly}
          onChange={updateFeatureFlags('reregistration_order')}
        />
        <Spacer size={10} />
        <PeriodTextField
          label={
            'Отключение в часах до начала' +
            getInfoForBeforeStart(
              values.startedDate,
              values.durationBeforeStartForTransferOrder,
            )
          }
          size="large"
          style={{ width: 500 }}
          disabled={readonly || !values.featureFlags.reregistration_order}
          fullWidth
          error={errors?.durationBeforeStartForTransferOrder}
          name="durationBeforeStartForTransferOrder"
          initValue={values.durationBeforeStartForTransferOrder!}
          onChange={(e) =>
            setBatchValues({
              durationBeforeStartForTransferOrder: e.target.value,
            })
          }
          period="hour"
        />
      </FormSection>
      <FormSection title="Тексты">
        <TextField
          tag="textarea"
          label="FAQ"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.faq}
          name="faq"
          initValue={values.faq!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Текст успешной покупки"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.successBuyText}
          name="successBuyText"
          initValue={values.successBuyText!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Глобальный текст тарифов"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.tariffsText}
          name="tariffsText"
          initValue={values.tariffsText!}
          onChange={onChangeValue}
        />
      </FormSection>
    </div>
  );
};
