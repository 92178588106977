import React, { FC, useEffect, useState } from 'react';
import { Redirect, useNavigate } from 'react-justanother-router';

import {
  ProductInput,
  ProductType,
  StatusEnum,
  TariffType,
  useEventQuery,
  useTariffsListQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  useModal,
} from '@/shared';
import { RouterName } from '@app/router';
import { EventForm, EventInputSteps, useUpdateEvent } from '@entities/Event';
import { Divider, LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export interface EventEditViewProps {
  params: {
    uuid: string;
  };
  query: {
    readonly?: boolean;
  };
}

export const EventEditView: FC<EventEditViewProps> = ({
  params: { uuid },
  query,
}) => {
  const openPublishModal = useModal('publishEvent');
  const { navigate } = useNavigate();
  const [updateEvent, { error, loading: saveLoading }] = useUpdateEvent();
  const [eventData, setEventData] = useState<ProductInput>();

  const {
    data,
    loading: loadingEvent,
    refetch,
  } = useEventQuery({
    variables: {
      uuid,
    },
  });
  const { data: tariffsData, loading: loadingTariffs } = useTariffsListQuery({
    variables: {
      productUuid: uuid,
      status: [StatusEnum.Active, StatusEnum.Draft, StatusEnum.Archive],
    },
  });
  const loading = loadingEvent || loadingTariffs;
  const product = data?.product;
  const tariffs = tariffsData?.tariffsList.rows || [];
  const initTariffsUuid = tariffs?.map((el) => el.uuid);
  const isDraft = product?.status === StatusEnum.Draft;
  const isArchive = product?.status === StatusEnum.Archive;
  const isReadonly = !!query.readonly;

  const onClose = () => {
    navigate(RouterName.AdminEvents);
  };
  const onPublish = () => {
    openPublishModal({ uuid });
  };
  const onSave = async () => {
    if (!eventData) {
      return toaster.error({ title: 'Отсутствуют данные для обновления' });
    }
    await updateEvent({
      input: eventData,
      uuid,
    });
    refetch();
  };

  useEffect(() => {
    if (
      eventData &&
      JSON.stringify(initTariffsUuid) !== JSON.stringify(eventData?.tariffsUuid)
    ) {
      updateEvent({
        input: {
          ...product,
          tariffsUuid: eventData.tariffsUuid,
        } as ProductInput,
        uuid,
      });
    }
  }, [eventData?.tariffsUuid]);

  const actions: ActionButton[] = isReadonly
    ? [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: () => navigate(RouterName.AdminEventEdit, { uuid }),
          label: 'Редактировать',
        },
      ]
    : [
        {
          handler: onClose,
          label: 'Отменить',
        },
        {
          handler: onSave,
          label: isDraft ? 'Сохранить черновик' : 'Сохранить изменения',
        },
        ...(isDraft
          ? [
              {
                handler: onPublish,
                label: 'Опубликовать',
              },
            ]
          : []),
      ];

  if (isArchive && !isReadonly) {
    return <Redirect to={RouterName.AdminEvents} />;
  }

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title={product?.name || ''} onBack={onClose} />
        <EventInputSteps data={product} />
      </Container>
      {isArchive ? (
        <Divider />
      ) : (
        <>
          <Spacer size={8} />
          <ActionButtons actions={actions} />
        </>
      )}
      <Spacer size={20} />
      <Container slim>
        {data && tariffsData && (
          <EventForm
            initValues={data.product as ProductType}
            initTariffs={tariffs as TariffType[]}
            onChange={setEventData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
            readonly={isReadonly}
          />
        )}
      </Container>
    </>
  );
};
