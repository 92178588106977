import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;

/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: number; output: number };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: string; output: string };
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian
   * calendar.This scalar is serialized to a string in ISO 8601 format and parsed
   * from a string in ISO 8601 format.
   */
  DateTimeISO: { input: number; output: number };
  /**
   * A field whose value conforms to the standard internet email address format as
   * specified in HTML Spec:
   * https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address.
   */
  EmailAddress: { input: string; output: string };
  /**
   * A string representing a duration conforming to the ISO8601 standard,
   * such as: P1W1DT13H23M34S
   * P is the duration designator (for period) placed at the start of the duration representation.
   * Y is the year designator that follows the value for the number of years.
   * M is the month designator that follows the value for the number of months.
   * W is the week designator that follows the value for the number of weeks.
   * D is the day designator that follows the value for the number of days.
   * T is the time designator that precedes the time components of the representation.
   * H is the hour designator that follows the value for the number of hours.
   * M is the minute designator that follows the value for the number of minutes.
   * S is the second designator that follows the value for the number of seconds.
   *
   * Note the time designator, T, that precedes the time value.
   *
   * Matches moment.js, Luxon and DateFns implementations
   * ,/. is valid for decimal places and +/- is a valid prefix
   */
  ISO8601Duration: { input: any; output: any };
  /**
   * A field whose value conforms to the standard E.164 format as specified in:
   * https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234.
   */
  PhoneNumber: { input: string; output: string };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File; output: File };
  /** Represents NULL values */
  Void: { input: any; output: any };
}

export interface BaseGraphModel {
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  updatedAt: Scalars['DateTimeISO']['output'];
}

export interface CreateInvoiceInput {
  uuidPairUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct: Scalars['UUID']['input'];
  uuidTariff: Scalars['UUID']['input'];
}

export interface CreateInvoiceParams {
  useBonusForPay?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ErrorType {
  __typename?: 'ErrorType';
  errors: Errors;
}

export enum Errors {
  AcquiringTurnOff = 'AcquiringTurnOff',
  AlreadyExecuted = 'AlreadyExecuted',
  AlreadyPaid = 'AlreadyPaid',
  CanActivateOnlyDraftItem = 'CanActivateOnlyDraftItem',
  CanArchiveOnlyActiveItem = 'CanArchiveOnlyActiveItem',
  CanNotActivateNotPaidOrder = 'CanNotActivateNotPaidOrder',
  CanNotActivateRefundedOrder = 'CanNotActivateRefundedOrder',
  CanNotChangeUserGender = 'CanNotChangeUserGender',
  CanNotClearCurrentUser = 'CanNotClearCurrentUser',
  CanNotCreateInvoiceWithDifferentGender = 'CanNotCreateInvoiceWithDifferentGender',
  CanNotDeactivateNotPaidOrder = 'CanNotDeactivateNotPaidOrder',
  CanNotDeactivateRefundedOrder = 'CanNotDeactivateRefundedOrder',
  CanNotEditAdminUser = 'CanNotEditAdminUser',
  CanNotPublishProductWithoutTariff = 'CanNotPublishProductWithoutTariff',
  CanNotTransferOrderUserWithDifferentGender = 'CanNotTransferOrderUserWithDifferentGender',
  CanRemoveOnlyDraftItem = 'CanRemoveOnlyDraftItem',
  CantRefundPairOrder = 'CantRefundPairOrder',
  CouldNotRefreshToken = 'CouldNotRefreshToken',
  EmailAlreadyConfirmed = 'EmailAlreadyConfirmed',
  FailedConfirmEmail = 'FailedConfirmEmail',
  FailedGetPayment = 'FailedGetPayment',
  FailedInitPayment = 'FailedInitPayment',
  FailedRemovePaymentAccount = 'FailedRemovePaymentAccount',
  FailedSendMail = 'FailedSendMail',
  FailedUpdateOrderUser = 'FailedUpdateOrderUser',
  FailedUploadFile = 'FailedUploadFile',
  InsufficientFundsOnBalance = 'InsufficientFundsOnBalance',
  InsufficientFundsOnUserBalance = 'InsufficientFundsOnUserBalance',
  InvalidAccessToken = 'InvalidAccessToken',
  InvalidAuthData = 'InvalidAuthData',
  InvalidCode = 'InvalidCode',
  InvalidFileType = 'InvalidFileType',
  NotFound = 'NotFound',
  NotFoundAccessToken = 'NotFoundAccessToken',
  NotPassedFullSupplierInfo = 'NotPassedFullSupplierInfo',
  NotPassedOrderForDepositUserBalance = 'NotPassedOrderForDepositUserBalance',
  NotSendCode = 'NotSendCode',
  OrderAlreadyActivated = 'OrderAlreadyActivated',
  OrderAlreadyDeactivated = 'OrderAlreadyDeactivated',
  OrganizerNotFound = 'OrganizerNotFound',
  PairUserNotFound = 'PairUserNotFound',
  PairUserNotPassed = 'PairUserNotPassed',
  PairUserNotSetGender = 'PairUserNotSetGender',
  PairUserWithSameGender = 'PairUserWithSameGender',
  PaymentAccountNotFound = 'PaymentAccountNotFound',
  PaymentNotFound = 'PaymentNotFound',
  PermissionDenied = 'PermissionDenied',
  ProductAlreadyHaveThisStatus = 'ProductAlreadyHaveThisStatus',
  ProductIsArchived = 'ProductIsArchived',
  ProductIsNotActive = 'ProductIsNotActive',
  ProductNotFound = 'ProductNotFound',
  TariffIsUnavailable = 'TariffIsUnavailable',
  TariffNotFound = 'TariffNotFound',
  TimeForRefundOrderExpired = 'TimeForRefundOrderExpired',
  TimeForTransferOrderExpired = 'TimeForTransferOrderExpired',
  UnknownError = 'UnknownError',
  UserAlreadyHaveOrder = 'UserAlreadyHaveOrder',
  UserAlreadySpecial = 'UserAlreadySpecial',
  UserBalanceNotFound = 'UserBalanceNotFound',
  UserNotFound = 'UserNotFound',
  UserNotFoundInSpecial = 'UserNotFoundInSpecial',
  UserNotSetGender = 'UserNotSetGender',
  UserWithThisEmailAlreadyExist = 'UserWithThisEmailAlreadyExist',
  UserWithThisTokenNotFound = 'UserWithThisTokenNotFound',
}

export interface FileType {
  __typename?: 'FileType';
  url: Scalars['String']['output'];
}

export interface FilterUsersInput {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface FinanceStatsType {
  __typename?: 'FinanceStatsType';
  profit: Scalars['Float']['output'];
  totalCommission: Scalars['Float']['output'];
  totalReceived: Scalars['Float']['output'];
  totalWithdrawals: Scalars['Float']['output'];
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male',
}

export interface GiveOrderResultType {
  __typename?: 'GiveOrderResultType';
  error?: Maybe<Errors>;
  id?: Maybe<Scalars['Float']['output']>;
  status: Scalars['Boolean']['output'];
  uuidUser: Scalars['UUID']['output'];
}

export type InvoiceType = NodeIdType & {
  __typename?: 'InvoiceType';
  amount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  invoice_link?: Maybe<Scalars['String']['output']>;
  payment_uuid?: Maybe<Scalars['UUID']['output']>;
  qr_link?: Maybe<Scalars['String']['output']>;
  status: PaymentStatusEnum;
};

export interface JwtType {
  __typename?: 'JwtType';
  access_token: Scalars['String']['output'];
  refresh_token: Scalars['String']['output'];
}

export enum LanguageEnum {
  English = 'English',
  Russian = 'Russian',
}

export interface LoginInput {
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}

export interface LoginTgInput {
  password: Scalars['String']['input'];
  tgId: Scalars['Float']['input'];
}

export interface Mutation {
  __typename?: 'Mutation';
  activateOrder: Scalars['Void']['output'];
  activateProduct: Scalars['Void']['output'];
  addPaymentAccount: PaymentAccountType;
  addUserOrganizer: Scalars['Void']['output'];
  addUserToSpecial: UserSpecialType;
  archiveProduct: Scalars['Void']['output'];
  cancelInvoice: Scalars['Void']['output'];
  cancelWithdrawal: Scalars['Void']['output'];
  changeLanguage: Scalars['Void']['output'];
  clearUserByTg: Scalars['Void']['output'];
  confirmEmail: Scalars['Void']['output'];
  createInvoice: InvoiceType;
  createOrganizer: OrganizerType;
  createProduct: ProductType;
  createTariff: TariffType;
  deactivateOrder: Scalars['Void']['output'];
  giveOrders: GiveOrderResultType[];
  login: JwtType;
  loginTg: JwtType;
  logout: Scalars['Void']['output'];
  refresh: JwtType;
  refundOrder: UserBalanceDepositType;
  removePaymentAccount: Scalars['Void']['output'];
  removeProduct: Scalars['Void']['output'];
  removeUserFromSpecial: Scalars['Void']['output'];
  requestWithdrawal: Scalars['Void']['output'];
  saveUserInfo: UserType;
  signUp: UserType;
  /** Method for transferring your order to another user. The order must not be completed */
  transferOrderToUser: OrderType;
  updateOrganizer: OrganizerType;
  updatePaymentAccount?: Maybe<PaymentAccountType>;
  updateProduct: ProductType;
  updateTariff: TariffType;
  updateUser: UserType;
  uploadFile: FileType;
  uploadImages: FileType[];
  verifyEmail: UserType;
  verifyEmailLater: UserType;
}

export interface MutationActivateOrderArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationActivateProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationAddPaymentAccountArgs {
  idOrganizer: Scalars['Int']['input'];
  input: PaymentAccountInput;
}

export interface MutationAddUserOrganizerArgs {
  idOrganizer: Scalars['Float']['input'];
}

export interface MutationAddUserToSpecialArgs {
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}

export interface MutationArchiveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelInvoiceArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationCancelWithdrawalArgs {
  idOrganizer: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}

export interface MutationChangeLanguageArgs {
  language: LanguageEnum;
}

export interface MutationClearUserByTgArgs {
  tgId: Scalars['Float']['input'];
}

export interface MutationConfirmEmailArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface MutationCreateInvoiceArgs {
  input: CreateInvoiceInput;
  params?: InputMaybe<CreateInvoiceParams>;
}

export interface MutationCreateOrganizerArgs {
  input: OrganizerInput;
}

export interface MutationCreateProductArgs {
  input: ProductInput;
  organizerId: Scalars['Float']['input'];
  publish?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface MutationCreateTariffArgs {
  input: TariffInput;
}

export interface MutationDeactivateOrderArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationGiveOrdersArgs {
  productUuid: Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  usersUuids: Scalars['UUID']['input'][];
}

export interface MutationLoginArgs {
  input: LoginInput;
}

export interface MutationLoginTgArgs {
  input: LoginTgInput;
}

export interface MutationRefreshArgs {
  refresh_token: Scalars['String']['input'];
}

export interface MutationRefundOrderArgs {
  orderId: Scalars['Int']['input'];
}

export interface MutationRemovePaymentAccountArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface MutationRemoveUserFromSpecialArgs {
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}

export interface MutationRequestWithdrawalArgs {
  input: WithdrawalOrganizerInput;
}

export interface MutationSaveUserInfoArgs {
  input: SaveUserInfoInput;
}

export interface MutationSignUpArgs {
  input: SignUpInput;
}

export interface MutationTransferOrderToUserArgs {
  orderId: Scalars['Int']['input'];
  transferUserUuid: Scalars['UUID']['input'];
}

export interface MutationUpdateOrganizerArgs {
  id: Scalars['Float']['input'];
  input: OrganizerInput;
}

export interface MutationUpdatePaymentAccountArgs {
  input: PaymentAccountInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateProductArgs {
  input: ProductInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateTariffArgs {
  input: TariffInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
  uuid: Scalars['UUID']['input'];
}

export interface MutationUploadFileArgs {
  file: Scalars['Upload']['input'];
}

export interface MutationUploadImagesArgs {
  image: Scalars['Upload']['input'][];
}

export interface MutationVerifyEmailArgs {
  code: Scalars['String']['input'];
}

export interface MutationVerifyEmailLaterArgs {
  email: Scalars['EmailAddress']['input'];
}

export interface NodeIdType {
  id: Scalars['ID']['output'];
}

export interface NodeUuidType {
  uuid: Scalars['UUID']['output'];
}

export enum OrderSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
}

export interface OrderSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrderSortKeys;
}

export enum OrderStatusEnum {
  CompletedAndNotVisited = 'CompletedAndNotVisited',
  CompletedAndVisited = 'CompletedAndVisited',
  NotPaid = 'NotPaid',
  Paid = 'Paid',
  Refunded = 'Refunded',
}

export type OrderType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'OrderType';
    availableAmountRefund: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    fixedHoldPercentage: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    organizer: OrganizerType;
    pairOrder?: Maybe<OrderType>;
    payment?: Maybe<PaymentType>;
    product: ProductType;
    status: OrderStatusEnum;
    tariff: TariffType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    /** Pay by bonus in this order */
    userBalanceWithdrawal?: Maybe<UserBalanceWithdrawalType>;
    uuid: Scalars['UUID']['output'];
  };

export interface OrdersFilterInput {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
}

export interface OrdersListFilterInput {
  order?: InputMaybe<OrderSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface OrdersParamsInput {
  status?: InputMaybe<OrderStatusEnum[]>;
}

export interface OrdersResponse {
  __typename?: 'OrdersResponse';
  count: Scalars['Int']['output'];
  rows: OrderType[];
}

export interface OrganizerFilesType {
  __typename?: 'OrganizerFilesType';
  agent?: Maybe<Scalars['String']['output']>;
  oferta?: Maybe<Scalars['String']['output']>;
  policy?: Maybe<Scalars['String']['output']>;
}

export interface OrganizerInput {
  description: Scalars['String']['input'];
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  socials: OrganizerSocialInput;
  supplierInfo: OrganizerSupplierInfoInput;
  telegram: Scalars['String']['input'];
  termsOfUse?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerListFilterInput {
  order?: InputMaybe<OrganizerSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<OrganizerSearchType>;
}

export enum OrganizerSearchKeys {
  Description = 'description',
  Name = 'name',
  Telegram = 'telegram',
}

export interface OrganizerSearchType {
  search: Scalars['String']['input'];
  searchBy: OrganizerSearchKeys;
}

export interface OrganizerSocialInput {
  facebook?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  telegram?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vk?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerSocialType {
  __typename?: 'OrganizerSocialType';
  facebook?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  telegram?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vk?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
}

export enum OrganizerSortKeys {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Telegram = 'telegram',
}

export interface OrganizerSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: OrganizerSortKeys;
}

export interface OrganizerSupplierInfoInput {
  inn?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface OrganizerSupplierInfoType {
  __typename?: 'OrganizerSupplierInfoType';
  inn?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
}

export type OrganizerType = BaseGraphModel &
  NodeIdType & {
    __typename?: 'OrganizerType';
    balance: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    files: OrganizerFilesType;
    id: Scalars['ID']['output'];
    image: Scalars['String']['output'];
    name: Scalars['String']['output'];
    paymentAccounts: PaymentAccountType[];
    products: ProductType[];
    productsCount: Scalars['Int']['output'];
    query?: Maybe<Scalars['String']['output']>;
    socials: OrganizerSocialType;
    supplierInfo: OrganizerSupplierInfoType;
    telegram: Scalars['String']['output'];
    termsOfUse?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    userBalance: Scalars['Float']['output'];
    withdrawals: WithdrawalType[];
  };

export interface OrganizersResponse {
  __typename?: 'OrganizersResponse';
  count: Scalars['Int']['output'];
  rows: OrganizerType[];
}

export interface PaginationType {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
}

export interface ParamsUsersInput {
  organizerId?: InputMaybe<Scalars['Float']['input']>;
}

export interface PaymentAccountInput {
  accountNumber: Scalars['String']['input'];
  bik: Scalars['String']['input'];
  inn: Scalars['String']['input'];
  name: Scalars['String']['input'];
}

export type PaymentAccountType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'PaymentAccountType';
    accountNumber: Scalars['String']['output'];
    bik: Scalars['String']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    inn: Scalars['String']['output'];
    name: Scalars['String']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export enum PaymentSortKeys {
  CreatedAt = 'createdAt',
  Price = 'price',
}

export interface PaymentSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: PaymentSortKeys;
}

export enum PaymentStatusEnum {
  Canceled = 'Canceled',
  Created = 'Created',
  Failed = 'Failed',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Success = 'Success',
}

export type PaymentType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'PaymentType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    invoice_url?: Maybe<Scalars['String']['output']>;
    order: OrderType;
    price: Scalars['Float']['output'];
    status: PaymentStatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export interface PaymentsArgsInput {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
}

export interface PaymentsListFilterInput {
  order?: InputMaybe<PaymentSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface PaymentsResponse {
  __typename?: 'PaymentsResponse';
  count: Scalars['Int']['output'];
  rows: PaymentType[];
}

export interface PlatformProfitStatsType {
  __typename?: 'PlatformProfitStatsType';
  profit: Scalars['Float']['output'];
}

export interface PreviewProductInput {
  active: Scalars['Boolean']['input'];
  back_button_text?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface PreviewProductType {
  __typename?: 'PreviewProductType';
  active: Scalars['Boolean']['output'];
  back_button_text?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ProductCountsType {
  __typename?: 'ProductCountsType';
  activeCount: Scalars['Float']['output'];
  archiveCount: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  draftCount: Scalars['Float']['output'];
}

export interface ProductFeatureFlagsInput {
  refund_order: Scalars['Boolean']['input'];
  reregistration_order: Scalars['Boolean']['input'];
}

export interface ProductFeatureFlagsType {
  __typename?: 'ProductFeatureFlagsType';
  refund_order: Scalars['Boolean']['output'];
  reregistration_order: Scalars['Boolean']['output'];
}

export interface ProductFilter {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
}

export interface ProductInput {
  activeAcquiring: Scalars['Boolean']['input'];
  address?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  durationBeforeStartForRefundOrder: Scalars['String']['input'];
  durationBeforeStartForTransferOrder: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  faq?: InputMaybe<Scalars['String']['input']>;
  featureFlags: ProductFeatureFlagsInput;
  img: Scalars['String']['input'];
  manualBuyText?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  preview: PreviewProductInput;
  query?: InputMaybe<Scalars['String']['input']>;
  route: RouteProductInput;
  startedDate: Scalars['DateTime']['input'];
  successBuyText?: InputMaybe<Scalars['String']['input']>;
  tariffsText?: InputMaybe<Scalars['String']['input']>;
  tariffsUuid: Scalars['UUID']['input'][];
}

export interface ProductListFilterInput {
  order?: InputMaybe<ProductSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<ProductSearchType>;
}

export enum ProductSearchKeysEnum {
  Description = 'description',
  Name = 'name',
}

export interface ProductSearchType {
  search: Scalars['String']['input'];
  searchBy: ProductSearchKeysEnum;
}

export enum ProductSortKeys {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  StartedDate = 'startedDate',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface ProductSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: ProductSortKeys;
}

export type ProductType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'ProductType';
    activeAcquiring: Scalars['Boolean']['output'];
    address?: Maybe<Scalars['String']['output']>;
    canRefundDate: Scalars['DateTime']['output'];
    canTransferDate: Scalars['DateTime']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description: Scalars['String']['output'];
    durationBeforeStartForRefundOrder: Scalars['ISO8601Duration']['output'];
    durationBeforeStartForTransferOrder: Scalars['ISO8601Duration']['output'];
    endDate: Scalars['DateTime']['output'];
    faq?: Maybe<Scalars['String']['output']>;
    featureFlags: ProductFeatureFlagsType;
    img: Scalars['String']['output'];
    manualBuyText?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    organizer: OrganizerType;
    preview: PreviewProductType;
    purchasedCount: Scalars['Float']['output'];
    query?: Maybe<Scalars['String']['output']>;
    receipts: Scalars['Float']['output'];
    route: RouteProductType;
    startedDate: Scalars['DateTime']['output'];
    status: StatusEnum;
    successBuyText?: Maybe<Scalars['String']['output']>;
    tariffs: TariffType[];
    tariffsText?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visitedCount: Scalars['Float']['output'];
  };

export interface ProductsResponse {
  __typename?: 'ProductsResponse';
  count: Scalars['Int']['output'];
  rows: ProductType[];
}

export interface PurchasesStatsType {
  __typename?: 'PurchasesStatsType';
  profit: Scalars['Float']['output'];
  usersCounts: UsersCountsType;
}

export interface Query {
  __typename?: 'Query';
  checkHasUserOrderByProduct: Scalars['Boolean']['output'];
  financeStats: FinanceStatsType;
  me: UserType;
  order: OrderType;
  orderByProductTariff: OrderType;
  orderByUuid: OrderType;
  /** Getting a list of orders for the user */
  orders: OrdersResponse;
  ordersList: OrdersResponse;
  organizer: OrganizerType;
  organizerByQuery: OrganizerType;
  organizerByTg: OrganizerType;
  organizers: OrganizersResponse;
  payment: PaymentType;
  payments: PaymentsResponse;
  paymentsByOrganizer: PaymentAccountType[];
  paymentsList: PaymentsResponse;
  platformProfitStats: PlatformProfitStatsType;
  product: ProductType;
  productByQuery: ProductType;
  products: ProductsResponse;
  productsCount: ProductCountsType;
  productsList: ProductsResponse;
  purchasesStats: PurchasesStatsType;
  searchUser?: Maybe<SearchUserType>;
  specialUsers: UsersResponse;
  tariff: TariffType;
  tariffs: TariffsResponse;
  tariffsList: TariffsResponse;
  user?: Maybe<UserType>;
  userBalance: UserBalanceType;
  users: UsersResponse;
  usersByProduct: UsersResponse;
  usersStats: UsersStatsType;
  withdrawalsByOrganizer: WithdrawalsResponse;
}

export interface QueryCheckHasUserOrderByProductArgs {
  productUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
}

export interface QueryFinanceStatsArgs {
  organizerId: Scalars['Int']['input'];
}

export interface QueryOrderArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrderByProductTariffArgs {
  status?: InputMaybe<OrderStatusEnum[]>;
  statusPayment?: InputMaybe<PaymentStatusEnum[]>;
  uuidProduct: Scalars['UUID']['input'];
  uuidTariff: Scalars['UUID']['input'];
}

export interface QueryOrderByUuidArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryOrdersArgs {
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QueryOrdersListArgs {
  args?: InputMaybe<OrdersFilterInput>;
  filters?: InputMaybe<OrdersListFilterInput>;
  params?: InputMaybe<OrdersParamsInput>;
}

export interface QueryOrganizerArgs {
  id: Scalars['Int']['input'];
}

export interface QueryOrganizerByQueryArgs {
  query: Scalars['String']['input'];
}

export interface QueryOrganizerByTgArgs {
  tg: Scalars['String']['input'];
}

export interface QueryOrganizersArgs {
  filters?: InputMaybe<OrganizerListFilterInput>;
}

export interface QueryPaymentArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryPaymentsArgs {
  filters?: InputMaybe<PaymentsListFilterInput>;
}

export interface QueryPaymentsByOrganizerArgs {
  idOrganizer: Scalars['Int']['input'];
}

export interface QueryPaymentsListArgs {
  args?: InputMaybe<PaymentsArgsInput>;
  filters?: InputMaybe<PaymentsListFilterInput>;
}

export interface QueryPlatformProfitStatsArgs {
  organizerId?: InputMaybe<Scalars['Int']['input']>;
}

export interface QueryProductArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryProductByQueryArgs {
  query: Scalars['String']['input'];
}

export interface QueryProductsArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
}

export interface QueryProductsCountArgs {
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
}

export interface QueryProductsListArgs {
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryPurchasesStatsArgs {
  organizerId: Scalars['Float']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}

export interface QuerySearchUserArgs {
  input: Scalars['String']['input'];
}

export interface QuerySpecialUsersArgs {
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
}

export interface QueryTariffArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryTariffsArgs {
  filters?: InputMaybe<TariffListFilterInput>;
  params?: InputMaybe<TariffParams>;
}

export interface QueryTariffsListArgs {
  filters?: InputMaybe<TariffListFilterInput>;
  params?: InputMaybe<TariffParams>;
  status?: InputMaybe<StatusEnum[]>;
}

export interface QueryUserArgs {
  uuid: Scalars['UUID']['input'];
}

export interface QueryUserBalanceArgs {
  organizerId: Scalars['Int']['input'];
}

export interface QueryUsersArgs {
  args: FilterUsersInput;
  filters?: InputMaybe<UserListFilterInput>;
  params: ParamsUsersInput;
}

export interface QueryUsersByProductArgs {
  filters?: InputMaybe<UserListFilterInput>;
  uuidProduct: Scalars['UUID']['input'];
}

export interface QueryUsersStatsArgs {
  args: FilterUsersInput;
  params: ParamsUsersInput;
}

export interface QueryWithdrawalsByOrganizerArgs {
  filters?: InputMaybe<WithdrawalListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
}

export enum RoleEnum {
  Admin = 'Admin',
  Organizer = 'Organizer',
  User = 'User',
}

export interface RouteProductInput {
  active: Scalars['Boolean']['input'];
  back_button_text?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface RouteProductType {
  __typename?: 'RouteProductType';
  active: Scalars['Boolean']['output'];
  back_button_text?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface SaveUserInfoInput {
  first_name: Scalars['String']['input'];
  gender: GenderEnum;
  last_name: Scalars['String']['input'];
  phone: Scalars['PhoneNumber']['input'];
}

export type SearchUserType = NodeUuidType & {
  __typename?: 'SearchUserType';
  email?: Maybe<Scalars['EmailAddress']['output']>;
  first_name: Scalars['String']['output'];
  gender?: Maybe<GenderEnum>;
  last_name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['PhoneNumber']['output']>;
  username: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export interface SignUpInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  language?: LanguageEnum;
  last_name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['PhoneNumber']['input']>;
  tgId: Scalars['Float']['input'];
  username: Scalars['String']['input'];
}

export enum SortEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum StatusEnum {
  Active = 'Active',
  Archive = 'Archive',
  Draft = 'Draft',
}

export interface TariffInput {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  ordersCount?: InputMaybe<Scalars['Float']['input']>;
  price: Scalars['Float']['input'];
  startedDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: TariffTypeEnum;
  visible: Scalars['Boolean']['input'];
}

export interface TariffListFilterInput {
  order?: InputMaybe<TariffSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<TariffSearchType>;
}

export interface TariffParams {
  productUuid: Scalars['UUID']['input'];
}

export enum TariffSearchKeysEnum {
  Description = 'description',
  Name = 'name',
  Type = 'type',
}

export interface TariffSearchType {
  search: Scalars['String']['input'];
  searchBy: TariffSearchKeysEnum;
}

export enum TariffSortKeysEnum {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  Name = 'name',
  Price = 'price',
  StartedDate = 'startedDate',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export interface TariffSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: TariffSortKeysEnum;
}

export type TariffType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'TariffType';
    availableOrdersCount?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    endDate?: Maybe<Scalars['DateTime']['output']>;
    name: Scalars['String']['output'];
    ordersCount?: Maybe<Scalars['Float']['output']>;
    price: Scalars['Float']['output'];
    startedDate?: Maybe<Scalars['DateTime']['output']>;
    status: StatusEnum;
    type: TariffTypeEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
    visible: Scalars['Boolean']['output'];
  };

export enum TariffTypeEnum {
  AnyGender = 'AnyGender',
  Female = 'Female',
  Male = 'Male',
  Pair = 'Pair',
}

export interface TariffsResponse {
  __typename?: 'TariffsResponse';
  count: Scalars['Int']['output'];
  rows: TariffType[];
}

export interface UpdateUserInput {
  email?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  gender?: InputMaybe<GenderEnum>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
}

export type UserBalanceDepositType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceDepositType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export type UserBalanceType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export type UserBalanceWithdrawalType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserBalanceWithdrawalType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export interface UserListFilterInput {
  order?: InputMaybe<UserSortType>;
  pagination?: InputMaybe<PaginationType>;
  search?: InputMaybe<Scalars['String']['input']>;
}

export enum UserSortKeys {
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'first_name',
  Gender = 'gender',
  LastName = 'last_name',
  Phone = 'phone',
  TgId = 'tgId',
  Username = 'username',
}

export interface UserSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: UserSortKeys;
}

export type UserSpecialType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserSpecialType';
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    organizer: OrganizerType;
    updatedAt: Scalars['DateTimeISO']['output'];
    user: UserType;
    uuid: Scalars['UUID']['output'];
  };

export type UserType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'UserType';
    activatedOrdersCount: Scalars['Int']['output'];
    controlOrganizerId?: Maybe<Scalars['Float']['output']>;
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    email?: Maybe<Scalars['EmailAddress']['output']>;
    first_name: Scalars['String']['output'];
    gender?: Maybe<GenderEnum>;
    hasOrderByProduct: Scalars['Boolean']['output'];
    isSpecial: Scalars['Boolean']['output'];
    language: LanguageEnum;
    last_name?: Maybe<Scalars['String']['output']>;
    ordersCount: Scalars['Int']['output'];
    organizers: OrganizerType[];
    phone?: Maybe<Scalars['PhoneNumber']['output']>;
    receipts: Scalars['Float']['output'];
    role: RoleEnum;
    tgId: Scalars['Float']['output'];
    updatedAt: Scalars['DateTimeISO']['output'];
    username: Scalars['String']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface UserTypeHasOrderByProductArgs {
  productUuid: Scalars['UUID']['input'];
}

export interface UserTypeIsSpecialArgs {
  idOrganizer: Scalars['Float']['input'];
}

export interface UsersCountsType {
  __typename?: 'UsersCountsType';
  all: Scalars['Float']['output'];
  female: Scalars['Float']['output'];
  male: Scalars['Float']['output'];
}

export interface UsersResponse {
  __typename?: 'UsersResponse';
  count: Scalars['Int']['output'];
  rows: UserType[];
}

export interface UsersStatsType {
  __typename?: 'UsersStatsType';
  count: Scalars['Int']['output'];
  femaleCount: Scalars['Int']['output'];
  maleCount: Scalars['Int']['output'];
}

export interface WithdrawalListFilterInput {
  order?: InputMaybe<WithdrawalSortType>;
  pagination?: InputMaybe<PaginationType>;
}

export interface WithdrawalOrganizerInput {
  amount: Scalars['Float']['input'];
  organizerId: Scalars['Int']['input'];
  uuidPaymentAccount: Scalars['UUID']['input'];
}

export enum WithdrawalSortKeys {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export interface WithdrawalSortType {
  sort?: InputMaybe<SortEnum>;
  sortBy: WithdrawalSortKeys;
}

export enum WithdrawalStatusEnum {
  Cancelled = 'Cancelled',
  Executed = 'Executed',
  Failed = 'Failed',
  InProgress = 'InProgress',
}

export type WithdrawalType = BaseGraphModel &
  NodeUuidType & {
    __typename?: 'WithdrawalType';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTimeISO']['output'];
    deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
    idOrganizer: Scalars['Int']['output'];
    organizer: OrganizerType;
    paymentAccount: PaymentAccountType;
    status: WithdrawalStatusEnum;
    updatedAt: Scalars['DateTimeISO']['output'];
    uuid: Scalars['UUID']['output'];
  };

export interface WithdrawalsResponse {
  __typename?: 'WithdrawalsResponse';
  count: Scalars['Int']['output'];
  rows: WithdrawalType[];
}

export interface JwtFieldsFragment {
  __typename?: 'JwtType';
  access_token: string;
  refresh_token: string;
}

export type LoginMutationVariables = Exact<{
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export interface LoginMutation {
  __typename?: 'Mutation';
  login: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type RefreshMutationVariables = Exact<{
  refresh_token: Scalars['String']['input'];
}>;

export interface RefreshMutation {
  __typename?: 'Mutation';
  refresh: {
    __typename?: 'JwtType';
    access_token: string;
    refresh_token: string;
  };
}

export type MeQueryVariables = Exact<Record<string, never>>;

export interface MeQuery {
  __typename?: 'Query';
  me: {
    __typename?: 'UserType';
    uuid: string;
    role: RoleEnum;
    username: string;
    controlOrganizerId?: number | null;
  };
}

export type UploadImagesMutationVariables = Exact<{
  images: Scalars['Upload']['input'][] | Scalars['Upload']['input'];
}>;

export interface UploadImagesMutation {
  __typename?: 'Mutation';
  uploadImages: { __typename?: 'FileType'; url: string }[];
}

export type FinanceStatsQueryVariables = Exact<{
  organizerId: Scalars['Int']['input'];
}>;

export interface FinanceStatsQuery {
  __typename?: 'Query';
  paymentsList: { __typename?: 'PaymentsResponse'; count: number };
  financeStats: {
    __typename?: 'FinanceStatsType';
    profit: number;
    totalCommission: number;
    totalReceived: number;
    totalWithdrawals: number;
  };
  organizer: { __typename?: 'OrganizerType'; balance: number };
}

export type PurchaseStatsQueryVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
}>;

export interface PurchaseStatsQuery {
  __typename?: 'Query';
  purchasesStats: {
    __typename?: 'PurchasesStatsType';
    profit: number;
    usersCounts: {
      __typename?: 'UsersCountsType';
      all: number;
      female: number;
      male: number;
    };
  };
}

export type PaymentsListQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsListFilterInput>;
  organizerId: Scalars['Int']['input'];
}>;

export interface PaymentsListQuery {
  __typename?: 'Query';
  paymentsList: {
    __typename?: 'PaymentsResponse';
    count: number;
    rows: {
      __typename?: 'PaymentType';
      uuid: string;
      price: number;
      createdAt: number;
      user: {
        __typename?: 'UserType';
        first_name: string;
        last_name?: string | null;
        email?: string | null;
        username: string;
        uuid: string;
      };
      order: {
        __typename?: 'OrderType';
        id: number;
        status: OrderStatusEnum;
        fixedHoldPercentage: number;
        product: {
          __typename?: 'ProductType';
          name: string;
          startedDate: string;
          endDate: string;
        };
        userBalanceWithdrawal?: {
          __typename?: 'UserBalanceWithdrawalType';
          amount: number;
        } | null;
        tariff: { __typename?: 'TariffType'; name: string };
      };
    }[];
  };
}

export type FinanceListsCountQueryVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
}>;

export interface FinanceListsCountQuery {
  __typename?: 'Query';
  paymentsList: { __typename?: 'PaymentsResponse'; count: number };
  withdrawalsByOrganizer: { __typename?: 'WithdrawalsResponse'; count: number };
}

export type OrdersListQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<OrderStatusEnum[] | OrderStatusEnum>;
}>;

export interface OrdersListQuery {
  __typename?: 'Query';
  ordersList: {
    __typename?: 'OrdersResponse';
    count: number;
    rows: {
      __typename?: 'OrderType';
      createdAt: number;
      id: number;
      status: OrderStatusEnum;
      fixedHoldPercentage: number;
      payment?: { __typename?: 'PaymentType'; price: number } | null;
      user: {
        __typename?: 'UserType';
        first_name: string;
        last_name?: string | null;
        email?: string | null;
        username: string;
        uuid: string;
        phone?: string | null;
      };
      product: {
        __typename?: 'ProductType';
        name: string;
        startedDate: string;
        endDate: string;
      };
      userBalanceWithdrawal?: {
        __typename?: 'UserBalanceWithdrawalType';
        amount: number;
      } | null;
      tariff: { __typename?: 'TariffType'; name: string };
    }[];
  };
}

export type OrdersCountQueryVariables = Exact<{
  filter?: InputMaybe<OrdersListFilterInput>;
  organizerId: Scalars['Int']['input'];
  uuidUser?: InputMaybe<Scalars['UUID']['input']>;
  uuidProduct?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<OrderStatusEnum[] | OrderStatusEnum>;
}>;

export interface OrdersCountQuery {
  __typename?: 'Query';
  ordersList: { __typename?: 'OrdersResponse'; count: number };
}

export type GiveOrdersMutationVariables = Exact<{
  usersUuids: Scalars['UUID']['input'][] | Scalars['UUID']['input'];
  tariffUuid: Scalars['UUID']['input'];
  productUuid: Scalars['UUID']['input'];
}>;

export interface GiveOrdersMutation {
  __typename?: 'Mutation';
  giveOrders: {
    __typename?: 'GiveOrderResultType';
    status: boolean;
    uuidUser: string;
    error?: Errors | null;
  }[];
}

export type OrganizerQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface OrganizerQuery {
  __typename?: 'Query';
  organizer: {
    __typename?: 'OrganizerType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    id: number;
    image: string;
    name: string;
    query?: string | null;
    description: string;
    telegram: string;
    termsOfUse?: string | null;
    socials: {
      __typename?: 'OrganizerSocialType';
      image?: string | null;
      text?: string | null;
      vk?: string | null;
      telegram?: string | null;
      facebook?: string | null;
      youtube?: string | null;
      instagram?: string | null;
    };
    supplierInfo: {
      __typename?: 'OrganizerSupplierInfoType';
      name?: string | null;
      inn?: string | null;
      phone?: string | null;
    };
  };
}

export type OrganizerFilesQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export interface OrganizerFilesQuery {
  __typename?: 'Query';
  organizer: {
    __typename?: 'OrganizerType';
    files: {
      __typename?: 'OrganizerFilesType';
      oferta?: string | null;
      agent?: string | null;
      policy?: string | null;
    };
  };
}

export type UpdateOrganizerMutationVariables = Exact<{
  id: Scalars['Float']['input'];
  input: OrganizerInput;
}>;

export interface UpdateOrganizerMutation {
  __typename?: 'Mutation';
  updateOrganizer: { __typename?: 'OrganizerType'; id: number };
}

export interface PaymentAccountFieldsFragment {
  __typename?: 'PaymentAccountType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: string;
  name: string;
  inn: string;
  bik: string;
  accountNumber: string;
}

export type PaymentAccountsListQueryVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
}>;

export interface PaymentAccountsListQuery {
  __typename?: 'Query';
  paymentsByOrganizer: {
    __typename?: 'PaymentAccountType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    uuid: string;
    name: string;
    inn: string;
    bik: string;
    accountNumber: string;
  }[];
}

export type EventsCountQueryVariables = Exact<{
  idOrganizer?: InputMaybe<Scalars['Float']['input']>;
}>;

export interface EventsCountQuery {
  __typename?: 'Query';
  productsCount: {
    __typename?: 'ProductCountsType';
    count: number;
    activeCount: number;
    archiveCount: number;
    draftCount: number;
  };
}

export type EventsListQueryVariables = Exact<{
  filters?: InputMaybe<ProductListFilterInput>;
  params?: InputMaybe<ProductFilter>;
  status?: InputMaybe<StatusEnum[] | StatusEnum>;
}>;

export interface EventsListQuery {
  __typename?: 'Query';
  productsList: {
    __typename?: 'ProductsResponse';
    count: number;
    rows: {
      __typename?: 'ProductType';
      createdAt: number;
      updatedAt: number;
      uuid: string;
      status: StatusEnum;
      purchasedCount: number;
      visitedCount: number;
      receipts: number;
      name: string;
      query?: string | null;
      startedDate: string;
      endDate: string;
    }[];
  };
}

export type EventQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface EventQuery {
  __typename?: 'Query';
  product: {
    __typename?: 'ProductType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    img: string;
    name: string;
    description: string;
    address?: string | null;
    query?: string | null;
    startedDate: string;
    endDate: string;
    status: StatusEnum;
    durationBeforeStartForRefundOrder: any;
    durationBeforeStartForTransferOrder: any;
    successBuyText?: string | null;
    tariffsText?: string | null;
    manualBuyText?: string | null;
    activeAcquiring: boolean;
    faq?: string | null;
    preview: {
      __typename?: 'PreviewProductType';
      active: boolean;
      url?: string | null;
      description?: string | null;
      back_button_text?: string | null;
    };
    route: {
      __typename?: 'RouteProductType';
      active: boolean;
      url?: string | null;
      description?: string | null;
      back_button_text?: string | null;
    };
    featureFlags: {
      __typename?: 'ProductFeatureFlagsType';
      refund_order: boolean;
      reregistration_order: boolean;
    };
  };
}

export type ArchiveEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface ArchiveEventMutation {
  __typename?: 'Mutation';
  archiveProduct: any;
}

export type PublishEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface PublishEventMutation {
  __typename?: 'Mutation';
  activateProduct: any;
}

export type RemoveEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface RemoveEventMutation {
  __typename?: 'Mutation';
  removeProduct: any;
}

export type UpdateEventMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: ProductInput;
}>;

export interface UpdateEventMutation {
  __typename?: 'Mutation';
  updateProduct: { __typename?: 'ProductType'; uuid: string };
}

export type CreateEventMutationVariables = Exact<{
  publish?: InputMaybe<Scalars['Boolean']['input']>;
  input: ProductInput;
  organizerId: Scalars['Float']['input'];
}>;

export interface CreateEventMutation {
  __typename?: 'Mutation';
  createProduct: { __typename?: 'ProductType'; uuid: string };
}

export interface TariffFieldsFragment {
  __typename?: 'TariffType';
  createdAt: number;
  updatedAt: number;
  uuid: string;
  name: string;
  description?: string | null;
  startedDate?: string | null;
  endDate?: string | null;
  availableOrdersCount?: number | null;
  ordersCount?: number | null;
  type: TariffTypeEnum;
  price: number;
  status: StatusEnum;
  visible: boolean;
}

export type TariffsListQueryVariables = Exact<{
  productUuid: Scalars['UUID']['input'];
  status: StatusEnum[] | StatusEnum;
}>;

export interface TariffsListQuery {
  __typename?: 'Query';
  tariffsList: {
    __typename?: 'TariffsResponse';
    rows: {
      __typename?: 'TariffType';
      uuid: string;
      name: string;
      startedDate?: string | null;
      endDate?: string | null;
      status: StatusEnum;
      type: TariffTypeEnum;
    }[];
  };
}

export type TariffQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export interface TariffQuery {
  __typename?: 'Query';
  tariff: {
    __typename?: 'TariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    description?: string | null;
    startedDate?: string | null;
    endDate?: string | null;
    availableOrdersCount?: number | null;
    ordersCount?: number | null;
    type: TariffTypeEnum;
    price: number;
    status: StatusEnum;
    visible: boolean;
  };
}

export type UpdateTariffMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: TariffInput;
}>;

export interface UpdateTariffMutation {
  __typename?: 'Mutation';
  updateTariff: {
    __typename?: 'TariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    description?: string | null;
    startedDate?: string | null;
    endDate?: string | null;
    availableOrdersCount?: number | null;
    ordersCount?: number | null;
    type: TariffTypeEnum;
    price: number;
    status: StatusEnum;
    visible: boolean;
  };
}

export type CreateTariffMutationVariables = Exact<{
  input: TariffInput;
}>;

export interface CreateTariffMutation {
  __typename?: 'Mutation';
  createTariff: {
    __typename?: 'TariffType';
    createdAt: number;
    updatedAt: number;
    uuid: string;
    name: string;
    description?: string | null;
    startedDate?: string | null;
    endDate?: string | null;
    availableOrdersCount?: number | null;
    ordersCount?: number | null;
    type: TariffTypeEnum;
    price: number;
    status: StatusEnum;
    visible: boolean;
  };
}

export type UserQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  organizerId: Scalars['Float']['input'];
}>;

export interface UserQuery {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserType';
    createdAt: number;
    updatedAt: number;
    deletedAt?: number | null;
    uuid: string;
    tgId: number;
    username: string;
    first_name: string;
    last_name?: string | null;
    language: LanguageEnum;
    role: RoleEnum;
    email?: string | null;
    phone?: string | null;
    ordersCount: number;
    activatedOrdersCount: number;
    receipts: number;
    gender?: GenderEnum | null;
    isSpecial: boolean;
  } | null;
}

export type UsersStatsQueryVariables = Exact<{
  organizerId?: InputMaybe<Scalars['Float']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface UsersStatsQuery {
  __typename?: 'Query';
  usersStats: {
    __typename?: 'UsersStatsType';
    count: number;
    maleCount: number;
    femaleCount: number;
  };
}

export type UpdateUserMutationVariables = Exact<{
  uuid: Scalars['UUID']['input'];
  input: UpdateUserInput;
}>;

export interface UpdateUserMutation {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'UserType'; uuid: string };
}

export type UsersListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId?: InputMaybe<Scalars['Float']['input']>;
  organizerSpecialId: Scalars['Float']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export interface UsersListQuery {
  __typename?: 'Query';
  users: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username: string;
      phone?: string | null;
      email?: string | null;
      receipts: number;
      activatedOrdersCount: number;
      ordersCount: number;
      isSpecial: boolean;
    }[];
  };
}

export type UsersSpecialListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
}>;

export interface UsersSpecialListQuery {
  __typename?: 'Query';
  specialUsers: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      createdAt: number;
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username: string;
      phone?: string | null;
      email?: string | null;
      receipts: number;
      activatedOrdersCount: number;
      ordersCount: number;
      isSpecial: boolean;
    }[];
  };
}

export type UsersSpecialProductListQueryVariables = Exact<{
  filters?: InputMaybe<UserListFilterInput>;
  organizerId: Scalars['Float']['input'];
  productUuid: Scalars['UUID']['input'];
}>;

export interface UsersSpecialProductListQuery {
  __typename?: 'Query';
  specialUsers: {
    __typename?: 'UsersResponse';
    count: number;
    rows: {
      __typename?: 'UserType';
      uuid: string;
      first_name: string;
      last_name?: string | null;
      username: string;
      phone?: string | null;
      email?: string | null;
      gender?: GenderEnum | null;
      hasOrderByProduct: boolean;
    }[];
  };
}

export type UsersSpecialCountQueryVariables = Exact<{
  organizerId: Scalars['Float']['input'];
}>;

export interface UsersSpecialCountQuery {
  __typename?: 'Query';
  specialUsers: { __typename?: 'UsersResponse'; count: number };
}

export type AddUserToSpecialMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;

export interface AddUserToSpecialMutation {
  __typename?: 'Mutation';
  addUserToSpecial: { __typename?: 'UserSpecialType'; uuid: string };
}

export type RemoveUserFromSpecialMutationVariables = Exact<{
  organizerId: Scalars['Float']['input'];
  userUuid: Scalars['UUID']['input'];
}>;

export interface RemoveUserFromSpecialMutation {
  __typename?: 'Mutation';
  removeUserFromSpecial: any;
}

export interface WithdrawalFieldsFragment {
  __typename?: 'WithdrawalType';
  createdAt: number;
  updatedAt: number;
  deletedAt?: number | null;
  uuid: string;
  amount: number;
  status: WithdrawalStatusEnum;
  organizer: { __typename?: 'OrganizerType'; name: string };
}

export type WithdrawalsListQueryVariables = Exact<{
  filter?: InputMaybe<WithdrawalListFilterInput>;
  idOrganizer: Scalars['Int']['input'];
}>;

export interface WithdrawalsListQuery {
  __typename?: 'Query';
  withdrawalsByOrganizer: {
    __typename?: 'WithdrawalsResponse';
    count: number;
    rows: {
      __typename?: 'WithdrawalType';
      createdAt: number;
      updatedAt: number;
      deletedAt?: number | null;
      uuid: string;
      amount: number;
      status: WithdrawalStatusEnum;
      paymentAccount: {
        __typename?: 'PaymentAccountType';
        createdAt: number;
        updatedAt: number;
        deletedAt?: number | null;
        uuid: string;
        name: string;
        inn: string;
        bik: string;
        accountNumber: string;
      };
      organizer: { __typename?: 'OrganizerType'; name: string };
    }[];
  };
}

export type WithdrawalRequestMutationVariables = Exact<{
  input: WithdrawalOrganizerInput;
}>;

export interface WithdrawalRequestMutation {
  __typename?: 'Mutation';
  requestWithdrawal: any;
}

export type CancelWithdrawalMutationVariables = Exact<{
  idOrganizer: Scalars['Int']['input'];
  uuid: Scalars['UUID']['input'];
}>;

export interface CancelWithdrawalMutation {
  __typename?: 'Mutation';
  cancelWithdrawal: any;
}

export const JwtFieldsFragmentDoc = gql`
  fragment JwtFields on JwtType {
    access_token
    refresh_token
  }
`;
export const PaymentAccountFieldsFragmentDoc = gql`
  fragment PaymentAccountFields on PaymentAccountType {
    createdAt
    updatedAt
    deletedAt
    uuid
    name
    inn
    bik
    accountNumber
  }
`;
export const TariffFieldsFragmentDoc = gql`
  fragment TariffFields on TariffType {
    createdAt
    updatedAt
    uuid
    name
    description
    startedDate
    endDate
    availableOrdersCount
    ordersCount
    type
    price
    status
    visible
  }
`;
export const WithdrawalFieldsFragmentDoc = gql`
  fragment WithdrawalFields on WithdrawalType {
    createdAt
    updatedAt
    deletedAt
    uuid
    amount
    status
    organizer {
      name
    }
  }
`;
export const LoginDocument = gql`
  mutation login($login: String!, $password: String!) {
    login(input: { login: $login, password: $password }) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RefreshDocument = gql`
  mutation refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      ...JwtFields
    }
  }
  ${JwtFieldsFragmentDoc}
`;
export type RefreshMutationFn = Apollo.MutationFunction<
  RefreshMutation,
  RefreshMutationVariables
>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshMutation,
    RefreshMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(
    RefreshDocument,
    options,
  );
}
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<
  RefreshMutation,
  RefreshMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      uuid
      role
      username
      controlOrganizerId
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UploadImagesDocument = gql`
  mutation uploadImages($images: [Upload!]!) {
    uploadImages(image: $images) {
      url
    }
  }
`;
export type UploadImagesMutationFn = Apollo.MutationFunction<
  UploadImagesMutation,
  UploadImagesMutationVariables
>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUploadImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadImagesMutation,
    UploadImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UploadImagesMutation,
    UploadImagesMutationVariables
  >(UploadImagesDocument, options);
}
export type UploadImagesMutationHookResult = ReturnType<
  typeof useUploadImagesMutation
>;
export type UploadImagesMutationResult =
  Apollo.MutationResult<UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<
  UploadImagesMutation,
  UploadImagesMutationVariables
>;
export const FinanceStatsDocument = gql`
  query financeStats($organizerId: Int!) {
    paymentsList(
      filters: { pagination: { page: 1, pageSize: 1 } }
      args: { organizerId: $organizerId }
    ) {
      count
    }
    financeStats(organizerId: $organizerId) {
      profit
      totalCommission
      totalReceived
      totalWithdrawals
    }
    organizer(id: $organizerId) {
      balance
    }
  }
`;

/**
 * __useFinanceStatsQuery__
 *
 * To run a query within a React component, call `useFinanceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useFinanceStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  > &
    (
      | { variables: FinanceStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export function useFinanceStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export function useFinanceStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FinanceStatsQuery,
    FinanceStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<FinanceStatsQuery, FinanceStatsQueryVariables>(
    FinanceStatsDocument,
    options,
  );
}
export type FinanceStatsQueryHookResult = ReturnType<
  typeof useFinanceStatsQuery
>;
export type FinanceStatsLazyQueryHookResult = ReturnType<
  typeof useFinanceStatsLazyQuery
>;
export type FinanceStatsSuspenseQueryHookResult = ReturnType<
  typeof useFinanceStatsSuspenseQuery
>;
export type FinanceStatsQueryResult = Apollo.QueryResult<
  FinanceStatsQuery,
  FinanceStatsQueryVariables
>;
export const PurchaseStatsDocument = gql`
  query purchaseStats($organizerId: Float!, $uuidProduct: UUID) {
    purchasesStats(organizerId: $organizerId, uuidProduct: $uuidProduct) {
      usersCounts {
        all
        female
        male
      }
      profit
    }
  }
`;

/**
 * __usePurchaseStatsQuery__
 *
 * To run a query within a React component, call `usePurchaseStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      uuidProduct: // value for 'uuidProduct'
 *   },
 * });
 */
export function usePurchaseStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  > &
    (
      | { variables: PurchaseStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(
    PurchaseStatsDocument,
    options,
  );
}
export function usePurchaseStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PurchaseStatsQuery, PurchaseStatsQueryVariables>(
    PurchaseStatsDocument,
    options,
  );
}
export function usePurchaseStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    PurchaseStatsQuery,
    PurchaseStatsQueryVariables
  >(PurchaseStatsDocument, options);
}
export type PurchaseStatsQueryHookResult = ReturnType<
  typeof usePurchaseStatsQuery
>;
export type PurchaseStatsLazyQueryHookResult = ReturnType<
  typeof usePurchaseStatsLazyQuery
>;
export type PurchaseStatsSuspenseQueryHookResult = ReturnType<
  typeof usePurchaseStatsSuspenseQuery
>;
export type PurchaseStatsQueryResult = Apollo.QueryResult<
  PurchaseStatsQuery,
  PurchaseStatsQueryVariables
>;
export const PaymentsListDocument = gql`
  query paymentsList($filter: PaymentsListFilterInput, $organizerId: Int!) {
    paymentsList(filters: $filter, args: { organizerId: $organizerId }) {
      count
      rows {
        uuid
        price
        createdAt
        user {
          first_name
          last_name
          email
          username
          uuid
        }
        order {
          id
          status
          fixedHoldPercentage
          product {
            name
            startedDate
            endDate
          }
          userBalanceWithdrawal {
            amount
          }
          tariff {
            name
          }
        }
      }
    }
  }
`;

/**
 * __usePaymentsListQuery__
 *
 * To run a query within a React component, call `usePaymentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function usePaymentsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentsListQuery,
    PaymentsListQueryVariables
  > &
    (
      | { variables: PaymentsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PaymentsListQuery, PaymentsListQueryVariables>(
    PaymentsListDocument,
    options,
  );
}
export function usePaymentsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentsListQuery,
    PaymentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PaymentsListQuery, PaymentsListQueryVariables>(
    PaymentsListDocument,
    options,
  );
}
export function usePaymentsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaymentsListQuery,
    PaymentsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<PaymentsListQuery, PaymentsListQueryVariables>(
    PaymentsListDocument,
    options,
  );
}
export type PaymentsListQueryHookResult = ReturnType<
  typeof usePaymentsListQuery
>;
export type PaymentsListLazyQueryHookResult = ReturnType<
  typeof usePaymentsListLazyQuery
>;
export type PaymentsListSuspenseQueryHookResult = ReturnType<
  typeof usePaymentsListSuspenseQuery
>;
export type PaymentsListQueryResult = Apollo.QueryResult<
  PaymentsListQuery,
  PaymentsListQueryVariables
>;
export const FinanceListsCountDocument = gql`
  query financeListsCount($idOrganizer: Int!) {
    paymentsList(
      args: { organizerId: $idOrganizer }
      filters: { pagination: { pageSize: 1, page: 1 } }
    ) {
      count
    }
    withdrawalsByOrganizer(
      idOrganizer: $idOrganizer
      filters: { pagination: { pageSize: 1, page: 1 } }
    ) {
      count
    }
  }
`;

/**
 * __useFinanceListsCountQuery__
 *
 * To run a query within a React component, call `useFinanceListsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceListsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceListsCountQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useFinanceListsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  > &
    (
      | { variables: FinanceListsCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export function useFinanceListsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export function useFinanceListsCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    FinanceListsCountQuery,
    FinanceListsCountQueryVariables
  >(FinanceListsCountDocument, options);
}
export type FinanceListsCountQueryHookResult = ReturnType<
  typeof useFinanceListsCountQuery
>;
export type FinanceListsCountLazyQueryHookResult = ReturnType<
  typeof useFinanceListsCountLazyQuery
>;
export type FinanceListsCountSuspenseQueryHookResult = ReturnType<
  typeof useFinanceListsCountSuspenseQuery
>;
export type FinanceListsCountQueryResult = Apollo.QueryResult<
  FinanceListsCountQuery,
  FinanceListsCountQueryVariables
>;
export const OrdersListDocument = gql`
  query ordersList(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
      rows {
        createdAt
        id
        status
        fixedHoldPercentage
        payment {
          price
        }
        user {
          first_name
          last_name
          email
          username
          uuid
          phone
        }
        product {
          name
          startedDate
          endDate
        }
        userBalanceWithdrawal {
          amount
        }
        tariff {
          name
        }
      }
    }
  }
`;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrdersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  > &
    (
      | { variables: OrdersListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export function useOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export function useOrdersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<
  typeof useOrdersListLazyQuery
>;
export type OrdersListSuspenseQueryHookResult = ReturnType<
  typeof useOrdersListSuspenseQuery
>;
export type OrdersListQueryResult = Apollo.QueryResult<
  OrdersListQuery,
  OrdersListQueryVariables
>;
export const OrdersCountDocument = gql`
  query ordersCount(
    $filter: OrdersListFilterInput
    $organizerId: Int!
    $uuidUser: UUID
    $uuidProduct: UUID
    $status: [OrderStatusEnum!]
  ) {
    ordersList(
      filters: $filter
      args: {
        organizerId: $organizerId
        uuidUser: $uuidUser
        uuidProduct: $uuidProduct
      }
      params: { status: $status }
    ) {
      count
    }
  }
`;

/**
 * __useOrdersCountQuery__
 *
 * To run a query within a React component, call `useOrdersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersCountQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      organizerId: // value for 'organizerId'
 *      uuidUser: // value for 'uuidUser'
 *      uuidProduct: // value for 'uuidProduct'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrdersCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  > &
    (
      | { variables: OrdersCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export function useOrdersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export function useOrdersCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrdersCountQuery,
    OrdersCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrdersCountQuery, OrdersCountQueryVariables>(
    OrdersCountDocument,
    options,
  );
}
export type OrdersCountQueryHookResult = ReturnType<typeof useOrdersCountQuery>;
export type OrdersCountLazyQueryHookResult = ReturnType<
  typeof useOrdersCountLazyQuery
>;
export type OrdersCountSuspenseQueryHookResult = ReturnType<
  typeof useOrdersCountSuspenseQuery
>;
export type OrdersCountQueryResult = Apollo.QueryResult<
  OrdersCountQuery,
  OrdersCountQueryVariables
>;
export const GiveOrdersDocument = gql`
  mutation giveOrders(
    $usersUuids: [UUID!]!
    $tariffUuid: UUID!
    $productUuid: UUID!
  ) {
    giveOrders(
      usersUuids: $usersUuids
      tariffUuid: $tariffUuid
      productUuid: $productUuid
    ) {
      status
      uuidUser
      error
    }
  }
`;
export type GiveOrdersMutationFn = Apollo.MutationFunction<
  GiveOrdersMutation,
  GiveOrdersMutationVariables
>;

/**
 * __useGiveOrdersMutation__
 *
 * To run a mutation, you first call `useGiveOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveOrdersMutation, { data, loading, error }] = useGiveOrdersMutation({
 *   variables: {
 *      usersUuids: // value for 'usersUuids'
 *      tariffUuid: // value for 'tariffUuid'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useGiveOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GiveOrdersMutation,
    GiveOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<GiveOrdersMutation, GiveOrdersMutationVariables>(
    GiveOrdersDocument,
    options,
  );
}
export type GiveOrdersMutationHookResult = ReturnType<
  typeof useGiveOrdersMutation
>;
export type GiveOrdersMutationResult =
  Apollo.MutationResult<GiveOrdersMutation>;
export type GiveOrdersMutationOptions = Apollo.BaseMutationOptions<
  GiveOrdersMutation,
  GiveOrdersMutationVariables
>;
export const OrganizerDocument = gql`
  query organizer($id: Int!) {
    organizer(id: $id) {
      createdAt
      updatedAt
      deletedAt
      id
      image
      name
      query
      description
      telegram
      termsOfUse
      socials {
        image
        text
        vk
        telegram
        facebook
        youtube
        instagram
      }
      supplierInfo {
        name
        inn
        phone
      }
    }
  }
`;

/**
 * __useOrganizerQuery__
 *
 * To run a query within a React component, call `useOrganizerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizerQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizerQuery,
    OrganizerQueryVariables
  > &
    (
      | { variables: OrganizerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganizerQuery, OrganizerQueryVariables>(
    OrganizerDocument,
    options,
  );
}
export function useOrganizerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizerQuery,
    OrganizerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganizerQuery, OrganizerQueryVariables>(
    OrganizerDocument,
    options,
  );
}
export function useOrganizerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganizerQuery,
    OrganizerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<OrganizerQuery, OrganizerQueryVariables>(
    OrganizerDocument,
    options,
  );
}
export type OrganizerQueryHookResult = ReturnType<typeof useOrganizerQuery>;
export type OrganizerLazyQueryHookResult = ReturnType<
  typeof useOrganizerLazyQuery
>;
export type OrganizerSuspenseQueryHookResult = ReturnType<
  typeof useOrganizerSuspenseQuery
>;
export type OrganizerQueryResult = Apollo.QueryResult<
  OrganizerQuery,
  OrganizerQueryVariables
>;
export const OrganizerFilesDocument = gql`
  query organizerFiles($id: Int!) {
    organizer(id: $id) {
      files {
        oferta
        agent
        policy
      }
    }
  }
`;

/**
 * __useOrganizerFilesQuery__
 *
 * To run a query within a React component, call `useOrganizerFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizerFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizerFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizerFilesQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  > &
    (
      | { variables: OrganizerFilesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganizerFilesQuery, OrganizerFilesQueryVariables>(
    OrganizerFilesDocument,
    options,
  );
}
export function useOrganizerFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganizerFilesQuery, OrganizerFilesQueryVariables>(
    OrganizerFilesDocument,
    options,
  );
}
export function useOrganizerFilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    OrganizerFilesQuery,
    OrganizerFilesQueryVariables
  >(OrganizerFilesDocument, options);
}
export type OrganizerFilesQueryHookResult = ReturnType<
  typeof useOrganizerFilesQuery
>;
export type OrganizerFilesLazyQueryHookResult = ReturnType<
  typeof useOrganizerFilesLazyQuery
>;
export type OrganizerFilesSuspenseQueryHookResult = ReturnType<
  typeof useOrganizerFilesSuspenseQuery
>;
export type OrganizerFilesQueryResult = Apollo.QueryResult<
  OrganizerFilesQuery,
  OrganizerFilesQueryVariables
>;
export const UpdateOrganizerDocument = gql`
  mutation updateOrganizer($id: Float!, $input: OrganizerInput!) {
    updateOrganizer(id: $id, input: $input) {
      id
    }
  }
`;
export type UpdateOrganizerMutationFn = Apollo.MutationFunction<
  UpdateOrganizerMutation,
  UpdateOrganizerMutationVariables
>;

/**
 * __useUpdateOrganizerMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizerMutation, { data, loading, error }] = useUpdateOrganizerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizerMutation,
    UpdateOrganizerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateOrganizerMutation,
    UpdateOrganizerMutationVariables
  >(UpdateOrganizerDocument, options);
}
export type UpdateOrganizerMutationHookResult = ReturnType<
  typeof useUpdateOrganizerMutation
>;
export type UpdateOrganizerMutationResult =
  Apollo.MutationResult<UpdateOrganizerMutation>;
export type UpdateOrganizerMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizerMutation,
  UpdateOrganizerMutationVariables
>;
export const PaymentAccountsListDocument = gql`
  query paymentAccountsList($idOrganizer: Int!) {
    paymentsByOrganizer(idOrganizer: $idOrganizer) {
      ...PaymentAccountFields
    }
  }
  ${PaymentAccountFieldsFragmentDoc}
`;

/**
 * __usePaymentAccountsListQuery__
 *
 * To run a query within a React component, call `usePaymentAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentAccountsListQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function usePaymentAccountsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  > &
    (
      | { variables: PaymentAccountsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export function usePaymentAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export function usePaymentAccountsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    PaymentAccountsListQuery,
    PaymentAccountsListQueryVariables
  >(PaymentAccountsListDocument, options);
}
export type PaymentAccountsListQueryHookResult = ReturnType<
  typeof usePaymentAccountsListQuery
>;
export type PaymentAccountsListLazyQueryHookResult = ReturnType<
  typeof usePaymentAccountsListLazyQuery
>;
export type PaymentAccountsListSuspenseQueryHookResult = ReturnType<
  typeof usePaymentAccountsListSuspenseQuery
>;
export type PaymentAccountsListQueryResult = Apollo.QueryResult<
  PaymentAccountsListQuery,
  PaymentAccountsListQueryVariables
>;
export const EventsCountDocument = gql`
  query eventsCount($idOrganizer: Float) {
    productsCount(idOrganizer: $idOrganizer) {
      count
      activeCount
      archiveCount
      draftCount
    }
  }
`;

/**
 * __useEventsCountQuery__
 *
 * To run a query within a React component, call `useEventsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsCountQuery({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useEventsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventsCountQuery,
    EventsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventsCountQuery, EventsCountQueryVariables>(
    EventsCountDocument,
    options,
  );
}
export function useEventsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventsCountQuery,
    EventsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventsCountQuery, EventsCountQueryVariables>(
    EventsCountDocument,
    options,
  );
}
export function useEventsCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventsCountQuery,
    EventsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventsCountQuery, EventsCountQueryVariables>(
    EventsCountDocument,
    options,
  );
}
export type EventsCountQueryHookResult = ReturnType<typeof useEventsCountQuery>;
export type EventsCountLazyQueryHookResult = ReturnType<
  typeof useEventsCountLazyQuery
>;
export type EventsCountSuspenseQueryHookResult = ReturnType<
  typeof useEventsCountSuspenseQuery
>;
export type EventsCountQueryResult = Apollo.QueryResult<
  EventsCountQuery,
  EventsCountQueryVariables
>;
export const EventsListDocument = gql`
  query eventsList(
    $filters: ProductListFilterInput
    $params: ProductFilter
    $status: [StatusEnum!]
  ) {
    productsList(filters: $filters, params: $params, status: $status) {
      rows {
        createdAt
        updatedAt
        uuid
        status
        purchasedCount
        visitedCount
        receipts
        name
        query
        startedDate
        endDate
      }
      count
    }
  }
`;

/**
 * __useEventsListQuery__
 *
 * To run a query within a React component, call `useEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      params: // value for 'params'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEventsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EventsListQuery,
    EventsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventsListQuery, EventsListQueryVariables>(
    EventsListDocument,
    options,
  );
}
export function useEventsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EventsListQuery,
    EventsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventsListQuery, EventsListQueryVariables>(
    EventsListDocument,
    options,
  );
}
export function useEventsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventsListQuery,
    EventsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventsListQuery, EventsListQueryVariables>(
    EventsListDocument,
    options,
  );
}
export type EventsListQueryHookResult = ReturnType<typeof useEventsListQuery>;
export type EventsListLazyQueryHookResult = ReturnType<
  typeof useEventsListLazyQuery
>;
export type EventsListSuspenseQueryHookResult = ReturnType<
  typeof useEventsListSuspenseQuery
>;
export type EventsListQueryResult = Apollo.QueryResult<
  EventsListQuery,
  EventsListQueryVariables
>;
export const EventDocument = gql`
  query event($uuid: UUID!) {
    product(uuid: $uuid) {
      createdAt
      updatedAt
      uuid
      img
      name
      description
      address
      query
      startedDate
      endDate
      status
      durationBeforeStartForRefundOrder
      durationBeforeStartForTransferOrder
      preview {
        active
        url
        description
        back_button_text
      }
      route {
        active
        url
        description
        back_button_text
      }
      featureFlags {
        refund_order
        reregistration_order
      }
      successBuyText
      tariffsText
      manualBuyText
      activeAcquiring
      faq
    }
  }
`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEventQuery(
  baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables> &
    ({ variables: EventQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export function useEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export function useEventSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    EventQuery,
    EventQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<EventQuery, EventQueryVariables>(
    EventDocument,
    options,
  );
}
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventSuspenseQueryHookResult = ReturnType<
  typeof useEventSuspenseQuery
>;
export type EventQueryResult = Apollo.QueryResult<
  EventQuery,
  EventQueryVariables
>;
export const ArchiveEventDocument = gql`
  mutation archiveEvent($uuid: UUID!) {
    archiveProduct(uuid: $uuid)
  }
`;
export type ArchiveEventMutationFn = Apollo.MutationFunction<
  ArchiveEventMutation,
  ArchiveEventMutationVariables
>;

/**
 * __useArchiveEventMutation__
 *
 * To run a mutation, you first call `useArchiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveEventMutation, { data, loading, error }] = useArchiveEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArchiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveEventMutation,
    ArchiveEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ArchiveEventMutation,
    ArchiveEventMutationVariables
  >(ArchiveEventDocument, options);
}
export type ArchiveEventMutationHookResult = ReturnType<
  typeof useArchiveEventMutation
>;
export type ArchiveEventMutationResult =
  Apollo.MutationResult<ArchiveEventMutation>;
export type ArchiveEventMutationOptions = Apollo.BaseMutationOptions<
  ArchiveEventMutation,
  ArchiveEventMutationVariables
>;
export const PublishEventDocument = gql`
  mutation publishEvent($uuid: UUID!) {
    activateProduct(uuid: $uuid)
  }
`;
export type PublishEventMutationFn = Apollo.MutationFunction<
  PublishEventMutation,
  PublishEventMutationVariables
>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePublishEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishEventMutation,
    PublishEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    PublishEventMutation,
    PublishEventMutationVariables
  >(PublishEventDocument, options);
}
export type PublishEventMutationHookResult = ReturnType<
  typeof usePublishEventMutation
>;
export type PublishEventMutationResult =
  Apollo.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = Apollo.BaseMutationOptions<
  PublishEventMutation,
  PublishEventMutationVariables
>;
export const RemoveEventDocument = gql`
  mutation removeEvent($uuid: UUID!) {
    removeProduct(uuid: $uuid)
  }
`;
export type RemoveEventMutationFn = Apollo.MutationFunction<
  RemoveEventMutation,
  RemoveEventMutationVariables
>;

/**
 * __useRemoveEventMutation__
 *
 * To run a mutation, you first call `useRemoveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEventMutation, { data, loading, error }] = useRemoveEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveEventMutation,
    RemoveEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RemoveEventMutation, RemoveEventMutationVariables>(
    RemoveEventDocument,
    options,
  );
}
export type RemoveEventMutationHookResult = ReturnType<
  typeof useRemoveEventMutation
>;
export type RemoveEventMutationResult =
  Apollo.MutationResult<RemoveEventMutation>;
export type RemoveEventMutationOptions = Apollo.BaseMutationOptions<
  RemoveEventMutation,
  RemoveEventMutationVariables
>;
export const UpdateEventDocument = gql`
  mutation updateEvent($uuid: UUID!, $input: ProductInput!) {
    updateProduct(input: $input, uuid: $uuid) {
      uuid
    }
  }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEventMutation,
    UpdateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(
    UpdateEventDocument,
    options,
  );
}
export type UpdateEventMutationHookResult = ReturnType<
  typeof useUpdateEventMutation
>;
export type UpdateEventMutationResult =
  Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventMutation,
  UpdateEventMutationVariables
>;
export const CreateEventDocument = gql`
  mutation createEvent(
    $publish: Boolean
    $input: ProductInput!
    $organizerId: Float!
  ) {
    createProduct(publish: $publish, input: $input, organizerId: $organizerId) {
      uuid
    }
  }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
  CreateEventMutation,
  CreateEventMutationVariables
>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      publish: // value for 'publish'
 *      input: // value for 'input'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEventMutation,
    CreateEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CreateEventDocument,
    options,
  );
}
export type CreateEventMutationHookResult = ReturnType<
  typeof useCreateEventMutation
>;
export type CreateEventMutationResult =
  Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
  CreateEventMutation,
  CreateEventMutationVariables
>;
export const TariffsListDocument = gql`
  query tariffsList($productUuid: UUID!, $status: [StatusEnum!]!) {
    tariffsList(
      params: { productUuid: $productUuid }
      status: $status
      filters: { order: { sort: Desc, sortBy: createdAt } }
    ) {
      rows {
        uuid
        name
        startedDate
        endDate
        status
        type
      }
    }
  }
`;

/**
 * __useTariffsListQuery__
 *
 * To run a query within a React component, call `useTariffsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTariffsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTariffsListQuery({
 *   variables: {
 *      productUuid: // value for 'productUuid'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTariffsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    TariffsListQuery,
    TariffsListQueryVariables
  > &
    (
      | { variables: TariffsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<TariffsListQuery, TariffsListQueryVariables>(
    TariffsListDocument,
    options,
  );
}
export function useTariffsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TariffsListQuery,
    TariffsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<TariffsListQuery, TariffsListQueryVariables>(
    TariffsListDocument,
    options,
  );
}
export function useTariffsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TariffsListQuery,
    TariffsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<TariffsListQuery, TariffsListQueryVariables>(
    TariffsListDocument,
    options,
  );
}
export type TariffsListQueryHookResult = ReturnType<typeof useTariffsListQuery>;
export type TariffsListLazyQueryHookResult = ReturnType<
  typeof useTariffsListLazyQuery
>;
export type TariffsListSuspenseQueryHookResult = ReturnType<
  typeof useTariffsListSuspenseQuery
>;
export type TariffsListQueryResult = Apollo.QueryResult<
  TariffsListQuery,
  TariffsListQueryVariables
>;
export const TariffDocument = gql`
  query tariff($uuid: UUID!) {
    tariff(uuid: $uuid) {
      ...TariffFields
    }
  }
  ${TariffFieldsFragmentDoc}
`;

/**
 * __useTariffQuery__
 *
 * To run a query within a React component, call `useTariffQuery` and pass it any options that fit your needs.
 * When your component renders, `useTariffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTariffQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTariffQuery(
  baseOptions: Apollo.QueryHookOptions<TariffQuery, TariffQueryVariables> &
    ({ variables: TariffQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<TariffQuery, TariffQueryVariables>(
    TariffDocument,
    options,
  );
}
export function useTariffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TariffQuery, TariffQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<TariffQuery, TariffQueryVariables>(
    TariffDocument,
    options,
  );
}
export function useTariffSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TariffQuery,
    TariffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<TariffQuery, TariffQueryVariables>(
    TariffDocument,
    options,
  );
}
export type TariffQueryHookResult = ReturnType<typeof useTariffQuery>;
export type TariffLazyQueryHookResult = ReturnType<typeof useTariffLazyQuery>;
export type TariffSuspenseQueryHookResult = ReturnType<
  typeof useTariffSuspenseQuery
>;
export type TariffQueryResult = Apollo.QueryResult<
  TariffQuery,
  TariffQueryVariables
>;
export const UpdateTariffDocument = gql`
  mutation updateTariff($uuid: UUID!, $input: TariffInput!) {
    updateTariff(input: $input, uuid: $uuid) {
      ...TariffFields
    }
  }
  ${TariffFieldsFragmentDoc}
`;
export type UpdateTariffMutationFn = Apollo.MutationFunction<
  UpdateTariffMutation,
  UpdateTariffMutationVariables
>;

/**
 * __useUpdateTariffMutation__
 *
 * To run a mutation, you first call `useUpdateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffMutation, { data, loading, error }] = useUpdateTariffMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTariffMutation,
    UpdateTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateTariffMutation,
    UpdateTariffMutationVariables
  >(UpdateTariffDocument, options);
}
export type UpdateTariffMutationHookResult = ReturnType<
  typeof useUpdateTariffMutation
>;
export type UpdateTariffMutationResult =
  Apollo.MutationResult<UpdateTariffMutation>;
export type UpdateTariffMutationOptions = Apollo.BaseMutationOptions<
  UpdateTariffMutation,
  UpdateTariffMutationVariables
>;
export const CreateTariffDocument = gql`
  mutation createTariff($input: TariffInput!) {
    createTariff(input: $input) {
      ...TariffFields
    }
  }
  ${TariffFieldsFragmentDoc}
`;
export type CreateTariffMutationFn = Apollo.MutationFunction<
  CreateTariffMutation,
  CreateTariffMutationVariables
>;

/**
 * __useCreateTariffMutation__
 *
 * To run a mutation, you first call `useCreateTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffMutation, { data, loading, error }] = useCreateTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTariffMutation,
    CreateTariffMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CreateTariffMutation,
    CreateTariffMutationVariables
  >(CreateTariffDocument, options);
}
export type CreateTariffMutationHookResult = ReturnType<
  typeof useCreateTariffMutation
>;
export type CreateTariffMutationResult =
  Apollo.MutationResult<CreateTariffMutation>;
export type CreateTariffMutationOptions = Apollo.BaseMutationOptions<
  CreateTariffMutation,
  CreateTariffMutationVariables
>;
export const UserDocument = gql`
  query User($uuid: UUID!, $organizerId: Float!) {
    user(uuid: $uuid) {
      createdAt
      updatedAt
      deletedAt
      uuid
      tgId
      username
      first_name
      last_name
      language
      role
      email
      phone
      ordersCount
      activatedOrdersCount
      receipts
      gender
      isSpecial(idOrganizer: $organizerId)
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables> &
    ({ variables: UserQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export function useUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<
  typeof useUserSuspenseQuery
>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersStatsDocument = gql`
  query usersStats($organizerId: Float, $isActive: Boolean) {
    usersStats(
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      count
      maleCount
      femaleCount
    }
  }
`;

/**
 * __useUsersStatsQuery__
 *
 * To run a query within a React component, call `useUsersStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersStatsQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUsersStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export function useUsersStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export function useUsersStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersStatsQuery,
    UsersStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersStatsQuery, UsersStatsQueryVariables>(
    UsersStatsDocument,
    options,
  );
}
export type UsersStatsQueryHookResult = ReturnType<typeof useUsersStatsQuery>;
export type UsersStatsLazyQueryHookResult = ReturnType<
  typeof useUsersStatsLazyQuery
>;
export type UsersStatsSuspenseQueryHookResult = ReturnType<
  typeof useUsersStatsSuspenseQuery
>;
export type UsersStatsQueryResult = Apollo.QueryResult<
  UsersStatsQuery,
  UsersStatsQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UsersListDocument = gql`
  query usersList(
    $filters: UserListFilterInput
    $organizerId: Float
    $organizerSpecialId: Float!
    $isActive: Boolean
  ) {
    users(
      filters: $filters
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        activatedOrdersCount
        ordersCount
        isSpecial(idOrganizer: $organizerSpecialId)
      }
      count
    }
  }
`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      organizerSpecialId: // value for 'organizerSpecialId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUsersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  > &
    (
      | { variables: UsersListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export function useUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export function useUsersListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<
  typeof useUsersListLazyQuery
>;
export type UsersListSuspenseQueryHookResult = ReturnType<
  typeof useUsersListSuspenseQuery
>;
export type UsersListQueryResult = Apollo.QueryResult<
  UsersListQuery,
  UsersListQueryVariables
>;
export const UsersSpecialListDocument = gql`
  query usersSpecialList($filters: UserListFilterInput, $organizerId: Float!) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      rows {
        createdAt
        uuid
        first_name
        last_name
        username
        phone
        email
        receipts
        activatedOrdersCount
        ordersCount
        isSpecial(idOrganizer: $organizerId)
      }
      count
    }
  }
`;

/**
 * __useUsersSpecialListQuery__
 *
 * To run a query within a React component, call `useUsersSpecialListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSpecialListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSpecialListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersSpecialListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  > &
    (
      | { variables: UsersSpecialListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UsersSpecialListQuery, UsersSpecialListQueryVariables>(
    UsersSpecialListDocument,
    options,
  );
}
export function useUsersSpecialListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >(UsersSpecialListDocument, options);
}
export function useUsersSpecialListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersSpecialListQuery,
    UsersSpecialListQueryVariables
  >(UsersSpecialListDocument, options);
}
export type UsersSpecialListQueryHookResult = ReturnType<
  typeof useUsersSpecialListQuery
>;
export type UsersSpecialListLazyQueryHookResult = ReturnType<
  typeof useUsersSpecialListLazyQuery
>;
export type UsersSpecialListSuspenseQueryHookResult = ReturnType<
  typeof useUsersSpecialListSuspenseQuery
>;
export type UsersSpecialListQueryResult = Apollo.QueryResult<
  UsersSpecialListQuery,
  UsersSpecialListQueryVariables
>;
export const UsersSpecialProductListDocument = gql`
  query usersSpecialProductList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $productUuid: UUID!
  ) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      rows {
        uuid
        first_name
        last_name
        username
        phone
        email
        gender
        hasOrderByProduct(productUuid: $productUuid)
      }
      count
    }
  }
`;

/**
 * __useUsersSpecialProductListQuery__
 *
 * To run a query within a React component, call `useUsersSpecialProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSpecialProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSpecialProductListQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      organizerId: // value for 'organizerId'
 *      productUuid: // value for 'productUuid'
 *   },
 * });
 */
export function useUsersSpecialProductListQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersSpecialProductListQuery,
    UsersSpecialProductListQueryVariables
  > &
    (
      | { variables: UsersSpecialProductListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    UsersSpecialProductListQuery,
    UsersSpecialProductListQueryVariables
  >(UsersSpecialProductListDocument, options);
}
export function useUsersSpecialProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersSpecialProductListQuery,
    UsersSpecialProductListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersSpecialProductListQuery,
    UsersSpecialProductListQueryVariables
  >(UsersSpecialProductListDocument, options);
}
export function useUsersSpecialProductListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersSpecialProductListQuery,
    UsersSpecialProductListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersSpecialProductListQuery,
    UsersSpecialProductListQueryVariables
  >(UsersSpecialProductListDocument, options);
}
export type UsersSpecialProductListQueryHookResult = ReturnType<
  typeof useUsersSpecialProductListQuery
>;
export type UsersSpecialProductListLazyQueryHookResult = ReturnType<
  typeof useUsersSpecialProductListLazyQuery
>;
export type UsersSpecialProductListSuspenseQueryHookResult = ReturnType<
  typeof useUsersSpecialProductListSuspenseQuery
>;
export type UsersSpecialProductListQueryResult = Apollo.QueryResult<
  UsersSpecialProductListQuery,
  UsersSpecialProductListQueryVariables
>;
export const UsersSpecialCountDocument = gql`
  query usersSpecialCount($organizerId: Float!) {
    specialUsers(
      organizerId: $organizerId
      filters: { pagination: { page: 1, pageSize: 1 } }
    ) {
      count
    }
  }
`;

/**
 * __useUsersSpecialCountQuery__
 *
 * To run a query within a React component, call `useUsersSpecialCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSpecialCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSpecialCountQuery({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *   },
 * });
 */
export function useUsersSpecialCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  > &
    (
      | { variables: UsersSpecialCountQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >(UsersSpecialCountDocument, options);
}
export function useUsersSpecialCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >(UsersSpecialCountDocument, options);
}
export function useUsersSpecialCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    UsersSpecialCountQuery,
    UsersSpecialCountQueryVariables
  >(UsersSpecialCountDocument, options);
}
export type UsersSpecialCountQueryHookResult = ReturnType<
  typeof useUsersSpecialCountQuery
>;
export type UsersSpecialCountLazyQueryHookResult = ReturnType<
  typeof useUsersSpecialCountLazyQuery
>;
export type UsersSpecialCountSuspenseQueryHookResult = ReturnType<
  typeof useUsersSpecialCountSuspenseQuery
>;
export type UsersSpecialCountQueryResult = Apollo.QueryResult<
  UsersSpecialCountQuery,
  UsersSpecialCountQueryVariables
>;
export const AddUserToSpecialDocument = gql`
  mutation addUserToSpecial($organizerId: Float!, $userUuid: UUID!) {
    addUserToSpecial(organizerId: $organizerId, userUuid: $userUuid) {
      uuid
    }
  }
`;
export type AddUserToSpecialMutationFn = Apollo.MutationFunction<
  AddUserToSpecialMutation,
  AddUserToSpecialMutationVariables
>;

/**
 * __useAddUserToSpecialMutation__
 *
 * To run a mutation, you first call `useAddUserToSpecialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToSpecialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToSpecialMutation, { data, loading, error }] = useAddUserToSpecialMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useAddUserToSpecialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToSpecialMutation,
    AddUserToSpecialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    AddUserToSpecialMutation,
    AddUserToSpecialMutationVariables
  >(AddUserToSpecialDocument, options);
}
export type AddUserToSpecialMutationHookResult = ReturnType<
  typeof useAddUserToSpecialMutation
>;
export type AddUserToSpecialMutationResult =
  Apollo.MutationResult<AddUserToSpecialMutation>;
export type AddUserToSpecialMutationOptions = Apollo.BaseMutationOptions<
  AddUserToSpecialMutation,
  AddUserToSpecialMutationVariables
>;
export const RemoveUserFromSpecialDocument = gql`
  mutation removeUserFromSpecial($organizerId: Float!, $userUuid: UUID!) {
    removeUserFromSpecial(organizerId: $organizerId, userUuid: $userUuid)
  }
`;
export type RemoveUserFromSpecialMutationFn = Apollo.MutationFunction<
  RemoveUserFromSpecialMutation,
  RemoveUserFromSpecialMutationVariables
>;

/**
 * __useRemoveUserFromSpecialMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromSpecialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromSpecialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromSpecialMutation, { data, loading, error }] = useRemoveUserFromSpecialMutation({
 *   variables: {
 *      organizerId: // value for 'organizerId'
 *      userUuid: // value for 'userUuid'
 *   },
 * });
 */
export function useRemoveUserFromSpecialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUserFromSpecialMutation,
    RemoveUserFromSpecialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    RemoveUserFromSpecialMutation,
    RemoveUserFromSpecialMutationVariables
  >(RemoveUserFromSpecialDocument, options);
}
export type RemoveUserFromSpecialMutationHookResult = ReturnType<
  typeof useRemoveUserFromSpecialMutation
>;
export type RemoveUserFromSpecialMutationResult =
  Apollo.MutationResult<RemoveUserFromSpecialMutation>;
export type RemoveUserFromSpecialMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserFromSpecialMutation,
  RemoveUserFromSpecialMutationVariables
>;
export const WithdrawalsListDocument = gql`
  query withdrawalsList(
    $filter: WithdrawalListFilterInput
    $idOrganizer: Int!
  ) {
    withdrawalsByOrganizer(idOrganizer: $idOrganizer, filters: $filter) {
      count
      rows {
        ...WithdrawalFields
        paymentAccount {
          ...PaymentAccountFields
        }
      }
    }
  }
  ${WithdrawalFieldsFragmentDoc}
  ${PaymentAccountFieldsFragmentDoc}
`;

/**
 * __useWithdrawalsListQuery__
 *
 * To run a query within a React component, call `useWithdrawalsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWithdrawalsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      idOrganizer: // value for 'idOrganizer'
 *   },
 * });
 */
export function useWithdrawalsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  > &
    (
      | { variables: WithdrawalsListQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<WithdrawalsListQuery, WithdrawalsListQueryVariables>(
    WithdrawalsListDocument,
    options,
  );
}
export function useWithdrawalsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >(WithdrawalsListDocument, options);
}
export function useWithdrawalsListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSuspenseQuery<
    WithdrawalsListQuery,
    WithdrawalsListQueryVariables
  >(WithdrawalsListDocument, options);
}
export type WithdrawalsListQueryHookResult = ReturnType<
  typeof useWithdrawalsListQuery
>;
export type WithdrawalsListLazyQueryHookResult = ReturnType<
  typeof useWithdrawalsListLazyQuery
>;
export type WithdrawalsListSuspenseQueryHookResult = ReturnType<
  typeof useWithdrawalsListSuspenseQuery
>;
export type WithdrawalsListQueryResult = Apollo.QueryResult<
  WithdrawalsListQuery,
  WithdrawalsListQueryVariables
>;
export const WithdrawalRequestDocument = gql`
  mutation withdrawalRequest($input: WithdrawalOrganizerInput!) {
    requestWithdrawal(input: $input)
  }
`;
export type WithdrawalRequestMutationFn = Apollo.MutationFunction<
  WithdrawalRequestMutation,
  WithdrawalRequestMutationVariables
>;

/**
 * __useWithdrawalRequestMutation__
 *
 * To run a mutation, you first call `useWithdrawalRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawalRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawalRequestMutation, { data, loading, error }] = useWithdrawalRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWithdrawalRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawalRequestMutation,
    WithdrawalRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    WithdrawalRequestMutation,
    WithdrawalRequestMutationVariables
  >(WithdrawalRequestDocument, options);
}
export type WithdrawalRequestMutationHookResult = ReturnType<
  typeof useWithdrawalRequestMutation
>;
export type WithdrawalRequestMutationResult =
  Apollo.MutationResult<WithdrawalRequestMutation>;
export type WithdrawalRequestMutationOptions = Apollo.BaseMutationOptions<
  WithdrawalRequestMutation,
  WithdrawalRequestMutationVariables
>;
export const CancelWithdrawalDocument = gql`
  mutation cancelWithdrawal($idOrganizer: Int!, $uuid: UUID!) {
    cancelWithdrawal(idOrganizer: $idOrganizer, uuid: $uuid)
  }
`;
export type CancelWithdrawalMutationFn = Apollo.MutationFunction<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;

/**
 * __useCancelWithdrawalMutation__
 *
 * To run a mutation, you first call `useCancelWithdrawalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWithdrawalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWithdrawalMutation, { data, loading, error }] = useCancelWithdrawalMutation({
 *   variables: {
 *      idOrganizer: // value for 'idOrganizer'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCancelWithdrawalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelWithdrawalMutation,
    CancelWithdrawalMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    CancelWithdrawalMutation,
    CancelWithdrawalMutationVariables
  >(CancelWithdrawalDocument, options);
}
export type CancelWithdrawalMutationHookResult = ReturnType<
  typeof useCancelWithdrawalMutation
>;
export type CancelWithdrawalMutationResult =
  Apollo.MutationResult<CancelWithdrawalMutation>;
export type CancelWithdrawalMutationOptions = Apollo.BaseMutationOptions<
  CancelWithdrawalMutation,
  CancelWithdrawalMutationVariables
>;
export const namedOperations = {
  Fragment: {
    JwtFields: 'JwtFields',
    PaymentAccountFields: 'PaymentAccountFields',
    TariffFields: 'TariffFields',
    WithdrawalFields: 'WithdrawalFields',
  },
  Mutation: {
    addUserToSpecial: 'addUserToSpecial',
    archiveEvent: 'archiveEvent',
    cancelWithdrawal: 'cancelWithdrawal',
    createEvent: 'createEvent',
    createTariff: 'createTariff',
    giveOrders: 'giveOrders',
    login: 'login',
    publishEvent: 'publishEvent',
    refresh: 'refresh',
    removeEvent: 'removeEvent',
    removeUserFromSpecial: 'removeUserFromSpecial',
    updateEvent: 'updateEvent',
    updateOrganizer: 'updateOrganizer',
    updateTariff: 'updateTariff',
    updateUser: 'updateUser',
    uploadImages: 'uploadImages',
    withdrawalRequest: 'withdrawalRequest',
  },
  Query: {
    User: 'User',
    event: 'event',
    eventsCount: 'eventsCount',
    eventsList: 'eventsList',
    financeListsCount: 'financeListsCount',
    financeStats: 'financeStats',
    me: 'me',
    ordersCount: 'ordersCount',
    ordersList: 'ordersList',
    organizer: 'organizer',
    organizerFiles: 'organizerFiles',
    paymentAccountsList: 'paymentAccountsList',
    paymentsList: 'paymentsList',
    purchaseStats: 'purchaseStats',
    tariff: 'tariff',
    tariffsList: 'tariffsList',
    usersList: 'usersList',
    usersSpecialCount: 'usersSpecialCount',
    usersSpecialList: 'usersSpecialList',
    usersSpecialProductList: 'usersSpecialProductList',
    usersStats: 'usersStats',
    withdrawalsList: 'withdrawalsList',
  },
};

export interface PossibleTypesResultData {
  possibleTypes: Record<string, string[]>;
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BaseGraphModel: [
      'OrderType',
      'OrganizerType',
      'PaymentAccountType',
      'PaymentType',
      'ProductType',
      'TariffType',
      'UserBalanceDepositType',
      'UserBalanceType',
      'UserBalanceWithdrawalType',
      'UserSpecialType',
      'UserType',
      'WithdrawalType',
    ],
    NodeIdType: ['InvoiceType', 'OrderType', 'OrganizerType'],
    NodeUuidType: [
      'PaymentAccountType',
      'PaymentType',
      'ProductType',
      'SearchUserType',
      'TariffType',
      'UserBalanceDepositType',
      'UserBalanceType',
      'UserBalanceWithdrawalType',
      'UserSpecialType',
      'UserType',
      'WithdrawalType',
    ],
  },
};

export default result;
