import { ProductInput, ProductType } from '@/apolloGenerated';

export const getEventInput = ({
  activeAcquiring,
  address,
  description,
  durationBeforeStartForRefundOrder,
  durationBeforeStartForTransferOrder,
  endDate,
  faq,
  featureFlags: { refund_order, reregistration_order },
  img,
  manualBuyText,
  name,
  preview: { active, back_button_text, description: descriptionPreview, url },
  query,
  route: {
    active: activeRoute,
    back_button_text: backButtonTextRoute,
    description: descriptionRoute,
    url: urlRoute,
  },
  startedDate,
  successBuyText,
  tariffsText,
  tariffsUuid,
}: ProductInput) => ({
  activeAcquiring,
  address,
  description,
  durationBeforeStartForRefundOrder,
  durationBeforeStartForTransferOrder,
  endDate,
  faq,
  featureFlags: { refund_order, reregistration_order },
  img,
  manualBuyText,
  name,
  preview: {
    active,
    back_button_text,
    description: descriptionPreview,
    url,
  },
  query,
  route: {
    active: activeRoute,
    back_button_text: backButtonTextRoute,
    description: descriptionRoute,
    url: urlRoute,
  },
  startedDate,
  successBuyText,
  tariffsText,
  tariffsUuid,
});

export const getEventInitState = (): ProductInput &
  Pick<ProductType, 'tariffs'> => ({
  activeAcquiring: true,
  address: null,
  description: '',
  durationBeforeStartForRefundOrder: 'P0D',
  durationBeforeStartForTransferOrder: 'P0D',
  endDate: new Date().toISOString(),
  faq: '',
  featureFlags: { refund_order: true, reregistration_order: true },
  img: '',
  manualBuyText: null,
  name: '',
  preview: {
    active: false,
    back_button_text: null,
    description: null,
    url: null,
  },
  query: null,
  route: {
    active: false,
    back_button_text: null,
    description: null,
    url: null,
  },
  startedDate: new Date().toISOString(),
  successBuyText: null,
  tariffs: [],
  tariffsText: null,
  tariffsUuid: [],
});
