import { GenderEnum, TariffTypeEnum } from '@/apolloGenerated';
import { Maybe } from '@letsdance/ui-kit';

export interface ValidationError {
  status: boolean;
  message?: string;
}
export const validateTariffGender = (
  userGender?: Maybe<GenderEnum>,
  type?: TariffTypeEnum,
): ValidationError => {
  if (type === TariffTypeEnum.Female && userGender !== GenderEnum.Female) {
    return {
      message: 'Тариф доступен только для женского пола',
      status: false,
    };
  }
  if (type === TariffTypeEnum.Male && userGender !== GenderEnum.Male) {
    return {
      message: 'Тариф доступен только для мужского пола',
      status: false,
    };
  }
  if (type === TariffTypeEnum.Pair) {
    return {
      message: 'Нельзя пригласить пользователя на парный тариф',
      status: false,
    };
  }

  return {
    status: true,
  };
};
export const validateTariffAvailable = (
  count?: Maybe<number>,
): ValidationError => {
  if (count !== null && count! - 1 <= 0) {
    return {
      message: 'Не осталось доступных билетов',
      status: false,
    };
  }

  return {
    status: true,
  };
};
export const validateTariffHasOrder = (
  hasOrderByProduct?: boolean,
): ValidationError => {
  if (hasOrderByProduct) {
    return {
      message: 'Пользователь уже имеет билет на данное событие',
      status: false,
    };
  }

  return {
    status: true,
  };
};

export const validateTariff = (
  userGender?: Maybe<GenderEnum>,
  type?: TariffTypeEnum,
  count?: Maybe<number>,
  hasOrderByProduct?: boolean,
): ValidationError => {
  const validateHasOrder = validateTariffHasOrder(hasOrderByProduct);

  if (!validateHasOrder.status) {
    return validateHasOrder;
  }
  const validateGender = validateTariffGender(userGender, type);

  if (!validateGender.status) {
    return validateGender;
  }

  return validateTariffAvailable(count);
};
