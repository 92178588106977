import React, { FC } from 'react';

import { TableCellLabelValue } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';

export interface CourseNameCellProps {
  name: string;
  query?: Maybe<string>;
}
export const EventNameCell: FC<CourseNameCellProps> = ({ name, query }) => (
  <TableCellLabelValue label={name} value={query || '-'} />
);

export const renderCellEventName = (args: CourseNameCellProps) => (
  <EventNameCell {...args} />
);
