import { FC } from 'react';

import { useUsersStatsQuery } from '@/apolloGenerated';
import { Container, StatItem, StatWrap, useOrganizerId } from '@/shared';
import { Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

const getStat = (stat?: number) => stat || 0;

export const ContactStats: FC = () => {
  const organizerId = useOrganizerId();
  const { data: statsData } = useUsersStatsQuery({
    skip: !organizerId,
  });
  const stats = statsData?.usersStats;

  const { data: activeStatsData } = useUsersStatsQuery({
    skip: !organizerId,
    variables: { isActive: true, organizerId },
  });
  const activeStats = activeStatsData?.usersStats;

  return (
    <Container className={styles.contactStats}>
      <StatWrap>
        <StatItem label="Всего контактов" value={getStat(stats?.count)} />
        <Divider vertical />
        <StatItem label="Мужчины" value={getStat(stats?.maleCount)} />
        <Divider vertical />
        <StatItem label="Женщины" value={getStat(stats?.femaleCount)} />
      </StatWrap>
      <StatWrap>
        <StatItem
          label="Активные пользователи"
          value={getStat(activeStats?.count)}
        />
        <Divider vertical />
        <StatItem label="Мужчины" value={getStat(activeStats?.maleCount)} />
        <Divider vertical />
        <StatItem label="Женщины" value={getStat(activeStats?.femaleCount)} />
      </StatWrap>
    </Container>
  );
};
