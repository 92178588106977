import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';
import moment from 'moment';

import {
  Maybe,
  OrdersListQuery,
  OrderSortKeys,
  OrderStatusEnum,
  useOrdersListQuery,
} from '@/apolloGenerated';
import {
  calculateProfit,
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { RouterName } from '@app/router';
import { LoaderOverlay, SortType, TableController } from '@letsdance/ui-kit';

const headers = [
  { hide: true, key: true, value: 'id' },
  { sortable: true, title: 'Покупка', value: 'createdAt' },
  { title: 'Событие', value: 'event' },
  { title: 'Пользователь', value: 'user' },
  { title: 'Начало', value: 'startDate' },
  { title: 'Завершение', value: 'endDate' },
  { title: 'Потрачено, ₽ ', value: 'price' },
  { title: 'Потрачено, Баллы ', value: 'bonus' },
];
const pageSize = 10;

export interface PurchasesOrdersTableProps {
  uuidProduct: Maybe<string>;
  status: OrderStatusEnum[];
}
export const PurchasesOrdersTable: FC<PurchasesOrdersTableProps> = ({
  status,
  uuidProduct,
}) => {
  const { urlFor } = useNavigate();
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<OrderSortKeys>(OrderSortKeys.CreatedAt);

  const {
    data: curData,
    loading,
    previousData,
  } = useOrdersListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
      status,
      uuidProduct,
    },
  });
  const data = curData || previousData;

  const rowTemplate = (order: OrdersListQuery['ordersList']['rows'][0]) => {
    const paymentAmounts = order.payment?.price
      ? calculateProfit(order.payment?.price, order.fixedHoldPercentage)
      : null;
    const bonusAmounts = order.userBalanceWithdrawal?.amount
      ? calculateProfit(
          order.userBalanceWithdrawal?.amount,
          order.fixedHoldPercentage,
        )
      : null;

    return {
      bonus: bonusAmounts?.profit ? formatAmount(bonusAmounts.profit) : '-',
      createdAt: renderCellItemValue({
        label: `ID: ${order.id}`,
        value: moment(order.createdAt).format('DD MMM YYYY HH:mm'),
      }),
      endDate: renderCellDate({ time: true, timestamp: order.product.endDate }),
      event: renderCellItemValue({
        label: order.product.name,
        value: order.tariff.name,
      }),
      id: String(order.id),
      price: paymentAmounts?.profit ? formatAmount(paymentAmounts.profit) : '-',
      startDate: renderCellDate({
        time: true,
        timestamp: order.product.startedDate,
      }),
      user: renderCellItemValue({
        label: `${order.user.last_name || ''} ${order.user.first_name}`,
        onClick: () =>
          window.open(
            urlFor(RouterName.AdminContactEdit, { uuid: order.user.uuid }),
            '_blank',
          ),
        value: order.user.phone || order.user.email,
      }),
    };
  };
  const items = useMemo(
    () => (data?.ordersList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.ordersList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as OrderSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
