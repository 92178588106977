import { TariffInput, TariffTypeEnum } from '@/apolloGenerated';

export const getTariffInput = ({
  description,
  endDate,
  name,
  ordersCount,
  price,
  startedDate,
  type,
  visible,
}: TariffInput) => ({
  description,
  endDate,
  name,
  ordersCount,
  price,
  startedDate,
  type,
  visible,
});

export const getTariffInitState = (): TariffInput => ({
  description: null,
  endDate: null,
  name: '',
  ordersCount: null,
  price: 0,
  startedDate: null,
  type: TariffTypeEnum.AnyGender,
  visible: true,
});
